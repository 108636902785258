@if (!loading) {
<div style="display: flex;gap:1rem;">
    <mat-form-field style="width: fit-content;" appearance="outline">
        <mat-label>År</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date">
        <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (yearSelected)="setYear($event, dp)">
        </mat-datepicker>
    </mat-form-field>

    <app-autocomplete-chips [formControl]="departmentControl" label="Forretningsområde"
        [displayFn]="departmentsService.displayFn" [valueFn]="departmentsService.valueFn" [options]="departments"
        [multiple]="false"></app-autocomplete-chips>

    <button mat-raised-button color="primary" (click)="exportExcel()" style="margin-top: 10px;">Eksportér til
        Excel</button>
    <button mat-raised-button color="primary" (click)="exportPdf()" style="margin-top: 10px;">Eksportér til PDF</button>
</div>

<div class="table-container">

    <table mat-table class="mat-elevation-z2" [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="consultant">
            <th mat-header-cell *matHeaderCellDef> Konsulent </th>
            <td mat-cell *matCellDef="let row"><span
                    [style.font-weight]="row.consultant.name == 'Total' ? '600' : ''">{{row.consultant.name}}</span>
            </td>
            <td mat-footer-cell *matFooterCellDef><strong>Total</strong></td>
        </ng-container>
        <ng-container matColumnDef="initials">
            <th mat-header-cell *matHeaderCellDef> Initialer </th>
            <td mat-cell *matCellDef="let row"> {{row.consultant.initials}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="personalRevenue">
            <th mat-header-cell *matHeaderCellDef> Personlig omsætning </th>
            <td mat-cell *matCellDef="let row" (click)="showDetails(row, 'personalRevenue')" class="right-align">
                {{row.personalRevenue | number:
                '1.0-0'}} </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals.personalRevenue | number:
                '1.0-0'}}</td>
        </ng-container>
        <ng-container matColumnDef="invoicedPersonalRevenue">
            <th mat-header-cell *matHeaderCellDef>Faktureret Personlig omsætning </th>
            <td mat-cell *matCellDef="let row" (click)="showDetails(row, 'invoicedPersonalRevenue')"
                class="right-align">
                {{row.invoicedPersonalRevenue
                | number: '1.0-0'}} </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals.invoicedPersonalRevenue
                | number: '1.0-0'}}</td>
        </ng-container>
        <ng-container matColumnDef="weightedPipeline">
            <th mat-header-cell *matHeaderCellDef> Vægtet pipeline </th>
            <td mat-cell *matCellDef="let row" (click)="showDetails(row, 'weightedPipeline')" class="right-align">
                {{row.weightedPipeline | number:
                '1.0-0'}} </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals.weightedPipeline | number:
                '1.0-0'}}</td>
        </ng-container>
        <ng-container matColumnDef="leads">
            <th mat-header-cell *matHeaderCellDef> Leads </th>
            <td mat-cell *matCellDef="let row" (click)="showDetails(row, 'leads')" class="right-align"> {{row.leads |
                number: '1.0-0'}} </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals.leads | number: '1.0-0'}}</td>
        </ng-container>
        <ng-container matColumnDef="realizedSales">
            <th mat-header-cell *matHeaderCellDef> Real. salg </th>
            <td mat-cell *matCellDef="let row" (click)="showDetails(row, 'realizedSales')" class="right-align">
                {{row.realizedSales | number: '1.0-0'}}
            </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals.realizedSales | number: '1.0-0'}}</td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                @if (element.consultant.id) {
                <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <app-consultant-summary-details [details]="details" [consultantId]="detailsConsultantId"
                        [year]="date.value.getFullYear()" [type]="detailsType"></app-consultant-summary-details>
                </div>
                }
            </td>
        </ng-container>
        <tr mat-header-row class="header-row" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
            [class.expanded-row]="expandedElement === row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        <tr mat-footer-row class="footer-row" *matFooterRowDef="displayedColumns;sticky:true"></tr>
    </table>
</div>
}