import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/ui/material.module';
import { OrderId } from '../manual-invoicing/manual-invoicing.component';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Moment } from 'moment';
import { InvoiceTask, InvoiceExpense, Invoice, Order } from 'src/app/models/order.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import { OrderService } from 'src/app/services/order.service';
import { Consultant } from 'src/app/models/consultant.model';
import { FeeBasisComponent } from 'src/app/fee-basis/fee-basis.component';
import { FeeBasisAdjustment } from 'src/app/models/fee-basis-adjustment.model';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DDMMYYYY_FORMAT } from 'src/app/invoices/invoice/invoice.component';
import { AutocompleteChipsComponent } from 'src/app/ui/autocomplete-chips/autocomplete-chips.component';
import { AppModule } from 'src/app/app.module';
import { FeeBasisAdjustmentService } from 'src/app/services/fee-basis-adjustment.service';

@Component({
  selector: 'app-fee-basis-adjustment',
  templateUrl: './fee-basis-adjustment.component.html',
  styleUrl: './fee-basis-adjustment.component.scss',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: DDMMYYYY_FORMAT },
  ]
})
export class FeeBasisAdjustmentComponent implements OnInit {
  @ViewChildren(NgModel) ngModels: QueryList<NgModel>;

  loading = true;

  formGroup: FormGroup;
  orderControl = new FormControl();
  dateControl = new FormControl();
  consultantControl = new FormControl();
  orderIds: { taskNumber: string; orderId: string; }[];
  consultants: Consultant[];
  order: Order;

  feeBasisAdjustment = new FeeBasisAdjustment();

  constructor(
    private snackBar: MatSnackBar,
    private orderService: OrderService,
    protected consultantService: ConsultantService,
    protected feeBasisAdjustmentService: FeeBasisAdjustmentService,
  ) {
    this.formGroup = new FormGroup({
      order: this.orderControl,
      date: this.dateControl,
      consultant: this.consultantControl,
    });
  }

  async ngOnInit(): Promise<void> {
    this.orderIds = await this.orderService.taskNumbers();
    this.consultants = await this.consultantService.list();

    this.orderControl.valueChanges.subscribe(async _ => {
      if (!_) return;
      
      this.order = await this.orderService.get(_);
      this.feeBasisAdjustment.orderId = _;
      this.feeBasisAdjustment.customer = this.order.customerAndTask;
    });

    this.consultantControl.valueChanges.subscribe(_ => {
      this.feeBasisAdjustment.consultantId = _;
    });

    this.loading = false;
  }

  orderDisplayFn(order: OrderId): string {
    return order && order.taskNumber ? order.taskNumber : '';
  }

  orderValueFn(order: OrderId): string {
    return order && order.orderId ? order.orderId : '';
  }

  dateSelected(event: MatDatepickerInputEvent<Moment>, datepicker: MatDatepicker<Date>) {
    if (datepicker) {
      datepicker.close();
    }

    this.feeBasisAdjustment.date = event.value.add(12, "hours").toDate();
  }

  async save() {
    if (!this.valid()) {
      this.formGroup.markAllAsTouched();
      this.ngModels.forEach(_ => _.control.markAllAsTouched());
    } else {
      try {
        await this.feeBasisAdjustmentService.save(this.feeBasisAdjustment);
        this.snackBar.open("Reguleringen er gemt");
        this.reset();
      } catch (error: any) {
        this.snackBar.open(error.message);
      }
    }
  }

  reset() {
    this.ngModels.forEach(_ => _.control.reset());
    this.formGroup.reset();
    this.feeBasisAdjustment = new FeeBasisAdjustment();
    this.order = null;
  }

  valid() {
    return this.formGroup.valid && this.ngModels.map(_ => _.valid).every(_ => _);
  }
}
