import { BaseModel } from "./base.model";
import { Order } from "./order.model";

export class FeeBasisAdjustment extends BaseModel {

    constructor(
        public consultantId?: string,
        public orderId?: string,
        public date?: Date,
        public invoiceNumber?: string,
        public customer?: string,
        public leadCreator?: number,
        public salesRealizator?: number,
        public executor?: number,
        public order?: Order
    ) {
        super();
    }
}
