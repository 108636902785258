import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Department } from 'src/app/models/department.model';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent {
  department: Department;

  formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DepartmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected departments: DepartmentService
  ) {
    this.department = data.department;

    this.formGroup = this.fb.group({
      name: [this.department.name, [Validators.required]],
    });
  }

  async save() {
    let result = { ...this.department, ...this.formGroup.value };

    try {
      await this.departments.save(result);
      this.dialogRef.close(result);
    } catch (error) {
      console.error(error);
    }
  }

  getControl(name: string) {
    return this.formGroup.get(name) as FormControl
  }
}
