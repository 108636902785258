import { AfterViewInit, ChangeDetectorRef, Component, Directive, ElementRef, HostListener, Injector, Input, OnInit, QueryList, ViewChild, ViewChildren, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, NgControl, NgModel, Validators, AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Observable, map, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-autocomplete-chips',
  templateUrl: './autocomplete-chips.component.html',
  styleUrl: './autocomplete-chips.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteChipsComponent),
      multi: true,
    }
  ]
})
export class AutocompleteChipsComponent<TOption> implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() appearance: MatFormFieldAppearance = "outline";
  @Input() label: string;
  @Input() placeholder = "Tilføj...";
  @Input() displayFn: (option: TOption) => string;
  @Input() valueFn: (option: TOption) => string;
  @Input() options: TOption[];
  @Input() required = false;
  @Input() multiple = true;
  @Input() debug = "";

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @HostListener("keydown.tab", ["$event"])
  tabPressed(event: KeyboardEvent) {
    let activeElem = document.activeElement;

    let queryString = [
      'a:not([disabled]):not([tabindex="-1"])',
      'button:not([disabled]):not([tabindex="-1"])',
      'input:not([disabled]):not([tabindex="-1"])',
      'select:not([disabled]):not([tabindex="-1"])',
      '[tabindex]:not([disabled]):not([tabindex="-1"])'
    ].join(','),
      queryResult = Array.prototype.filter.call(document.querySelectorAll(queryString), elem => {
        return elem.offsetWidth > 0 || elem.offsetHeight > 0 || elem === activeElem;
      }),
      indexedList = queryResult.slice().filter(elem => {
        return elem.tabIndex == 0 || elem.tabIndex == -1 ? false : true;
      }).sort((a, b) => {
        return a.tabIndex != 0 && b.tabIndex != 0
          ? (a.tabIndex < b.tabIndex ? -1 : b.tabIndex < a.tabIndex ? 1 : 0)
          : a.tabIndex != 0 ? -1 : b.tabIndex != 0 ? 1 : 0;
      }),
      focusable = [].concat(indexedList, queryResult.filter(elem => {
        return elem.tabIndex == 0 || elem.tabIndex == -1 ? true : false;
      }));

    let el = (focusable[focusable.indexOf(activeElem) + 1] || focusable[0]);
    let otherAc = el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement as HTMLElement;
    if (otherAc && otherAc?.tagName?.toLowerCase() == "app-autocomplete-chips") {
      let input = otherAc.getElementsByTagName("input");
      input?.item(0)?.focus();
      otherAc.focus();
    } else {
      el.focus();
    }
  }

  focused = false;
  control: FormControl;
  inputFormControl = new FormControl("");
  separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  filteredOptions: Observable<TOption[]>;

  constructor(
    protected injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    this.filteredOptions = this.inputFormControl.valueChanges.pipe(
      startWith(null),
      map((option: string | null) => (option ? this.filter(option)
        : this.options?.filter(
          o => this.multiple ? !this.control.value?.find(v => v == this.valueFn(o))
            : this.control.value != this.valueFn(o)))),
    );
  }

  ngOnInit(): void {
    if (!this.displayFn)
      throw new Error("No 'displayFn' provided");

    if (!this.valueFn)
      throw new Error("No 'valueFn' provided");

    if (!this.options)
      throw new Error("No 'options' provided");

    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      this.control = ngControl.control as FormControl;

      if (this.required) {
        this.control.addValidators(Validators.required);
      }
    }

    if (!this.control)
      throw new Error("No 'formControl' provided");

    if (this.multiple)
      this.control.setValue([]);
  }

  ngAfterViewInit(): void {
  }

  writeValue(obj: any): void {
    this.inputFormControl.setValue(null);
  }

  onChange: (value: string) => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouched: () => void;
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  option(value: string) {
    return this.options.find(_ => this.valueFn(_) == value);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add option
    if (value) {
      this.addValue(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.inputFormControl.setValue(null);
  }

  remove(optionValue: string): void {
    this.control.setValue(this.multiple ? this.control.value?.filter(_ => _ != optionValue) : null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addValue(event.option.viewValue);
    this.input.nativeElement.value = '';
    this.inputFormControl.setValue(null);
  }

  private filter(value: string): TOption[] {
    const filterValue = value?.toLowerCase();
    return this.options
      .filter(option => this.displayFn(option)?.toLowerCase().includes(filterValue))
      .filter(
        o => this.multiple ? !this.control.value?.find(v => v == this.valueFn(o))
          : this.control.value != this.valueFn(o));
  }

  private addValue(displayValue: string) {
    if (this.debug)
      console.log(this.debug + ": addValue", displayValue);

    if (displayValue) {
      const option = this.options.find(_ => this.displayFn(_)?.toLowerCase() == displayValue?.toLowerCase());

      if (option) {
        if (this.multiple && !this.control.value?.find(_ => _ == this.valueFn(option)))
          this.control.setValue([...this.control.value, this.valueFn(option)]);

        if (!this.multiple && this.control.value != this.valueFn(option))
          this.control.setValue(this.valueFn(option));
      }
    }
  }
}
