import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Invoice, InvoiceExpense, InvoiceStatus, InvoiceTask, Order } from 'src/app/models/order.model';
import { OrderService } from 'src/app/services/order.service';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { Consultant, DistributionAmount, ExecutorAmount, SalesRealizatorAmount } from 'src/app/models/consultant.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import * as moment from 'moment';
import { Department } from 'src/app/models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Settings } from 'src/app/models/settings.model';

export const DDMMYYYY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MMMM YYYY',
  },
};

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: DDMMYYYY_FORMAT },
  ]
})
export class InvoiceComponent implements OnInit, AfterViewInit {
  loading = true;

  settings: Settings;
  order: Order;
  invoice: Invoice;
  fromOrder = false;

  projectLeadDropdown = [];
  projectLeadControl: FormControl;
  consultants: Consultant[];
  productsDropdown = [];
  @ViewChildren(NgModel) ngModels: QueryList<NgModel>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<InvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected orders: OrderService,
    protected consultantsService: ConsultantService,
    protected departmentsService: DepartmentService,
    protected invoiceService: InvoiceService,
    protected settingsService: SettingsService,
    protected cd: ChangeDetectorRef
  ) {
    this.order = this.data.order;
    this.invoice = this.data.invoice;
    this.fromOrder = this.data.fromOrder;
  }

  async ngOnInit(): Promise<void> {

  }

  async ngAfterViewInit(): Promise<void> {

  }

  close(confirm = false) {
    if (confirm && window.confirm("Dine ændringer vil ikke blive gemt")) {
      this.dialogRef.close(false);
    } else {
      this.dialogRef.close(true);
    }
  }

  async save() {
    try {
      // if (!this.ngModels.map(_ => _.valid).every(_ => _)) {
      //   this.projectLeadControl.markAllAsTouched();
      //   this.ngModels.forEach(_ => _.control.markAllAsTouched());
      // } else {
      //   if (!this.invoice.id)
      //     this.invoice.salesRealizatorDistribution = this.order.salesRealizators.map(
      //       _ => new DistributionAmount(_.consultantId, this.order.id, (_.amount / this.order.projectSumExVat) * this.totalTasks()));
      //   let result = await this.invoiceService.save(this.invoice);
      //   this.dialogRef.close(result);
      // }
    } catch (error) {
      console.log(error);
    }
  }
}
