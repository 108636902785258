<div class="pivot-dropdown">
  <mat-form-field appearance="outline">
    <mat-label>{{ title }}</mat-label>
    <mat-select [(ngModel)]="selectedOption" (ngModelChange)="emitValueChange()">
      <mat-option [value]="undefined">Nulstil</mat-option>
      @for (option of options; track option) {
        <mat-option [value]="option.value">{{ option.display }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>