<div style="display: flex;flex-direction: row;">
    <h2 mat-dialog-title style="display: inline;flex: auto;">Forretningsområde</h2>
    <button mat-button mat-dialog-close>X</button>
</div>
<mat-dialog-content>
    <form [formGroup]="formGroup">

        <div class="form-row">
            <mat-form-field appearance="outline">
                <mat-label>Navn</mat-label>
                <input matInput type="text" formControlName="name">
                <!-- <mat-error *ngIf="false">{{ defaultErrorMessage }}</mat-error> -->
            </mat-form-field>
        </div>

    </form>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close>Annuller</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="formGroup.invalid">Gem</button>
</mat-dialog-actions>