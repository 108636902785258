<mat-form-field [appearance]="appearance" style="display: flex !important;">
  <mat-label>{{ label }}</mat-label>
  <mat-select #selectElem [placeholder]="placeholder" [formControl]="formControl" [multiple]="multiple"
    (selectionChange)="onSelectionChange($event)" [required]="required">
    @if (searchbar) {
      <div class="box-search">
        <ng-container >
          <input #searchInput type="text" name="search" [ngClass]="{ 'pl-1': !multiple }" (input)="filterItem(searchInput.value)"
            [placeholder]="selectPlaceholder" />
            <div class="box-search-icon" (click)="filterItem(''); searchInput.value = ''">
              <button mat-icon-button class="search-button">
                <mat-icon class="mat-24" aria-label="Search icon">clear</mat-icon>
              </button>
            </div>
          </ng-container>
          <button mat-button (click)="toggleDropdown()">OK
          </button>
        </div>
      }

      <mat-select-trigger>
        {{ onDisplayString() }}
      </mat-select-trigger>
      @for (option of options; track trackByFn($index, option)) {
        <mat-option [disabled]="option.disabled" [value]="option[value]"
          [style.display]="hideOption(option) ? 'none' : 'flex'">{{ option[display] }}
        </mat-option>
      }
    </mat-select>
    @if (showErrorMsg) {
      <mat-hint style="color:red">{{ errorMsg }}</mat-hint>
    }
  </mat-form-field>