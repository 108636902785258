import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const groups = {
    admin: "d7153e38-aae0-44de-9af3-30e15142745b",
    consultant: "c4b62735-3801-45da-aa06-c2f1cb2671ac"
}

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.azureAdClientId, // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/b45e6e2f-cb78-4806-9ab5-de04aab45b3e', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: '/', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: '/', // Points to window.location.origin by default.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
        // loggerOptions: {
        //     loggerCallback(logLevel: LogLevel, message: string) {
        //         console.log(message);
        //     },
        //     logLevel: LogLevel.Verbose,
        //     piiLoggingEnabled: true
        // }
    }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    apiApp: {
        endpoint: environment.azureAdProtectedResource,
        scopes: ["api://79277651-a13e-4abc-a8ce-c42db3ebc526/access_via_group_assignments"]
    },
    apiGraph: {
        endpoint: "https://graph.microsoft.com/v1.0/me/checkMemberGroups",
        scopes: ["User.Read", "GroupMember.Read.All"]
    }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [...protectedResources.apiApp.scopes]
};

export const CACHE_TTL_IN_MS = 60 * 60 * 1000; // 1 hour in milliseconds
