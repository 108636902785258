import { BaseModel } from "./base.model";

export class Settings extends BaseModel {

    constructor(
        public economicApiKey?: string,
        public transportFee?: number,
        public products?: Product[]
    ) {
        super();

    }
}

export class Product extends BaseModel {

    constructor(
        public number?: string,
        public name?: string,
    ) {
        super();

    }
}