import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, HostListener, Inject, Input, OnInit, QueryList, ViewChild, ViewChildren, forwardRef, input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, AsyncValidator, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NgModel, ValidationErrors, Validator, Validators } from '@angular/forms';
import { ExpectedInvoice, Invoice, Order } from 'src/app/models/order.model';
import { OrderService } from 'src/app/services/order.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Consultant, ExecutorAmount, SalesRealizatorAmount } from 'src/app/models/consultant.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import { Department } from 'src/app/models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { formatNumber } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { InvoiceComponent } from 'src/app/invoices/invoice/invoice.component';



export const YEAR_FORMAT = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
export const MONTH_YEAR_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Directive({
  selector: '[monthYearFormat]',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMAT },
  ],
})
export class MonthYearFormat {
}

@Directive({
  selector: '[yearFormat]',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMAT },
  ],
})
export class YearFormat {
}

@Directive({
  selector: '[enterNavigation]',
  providers: [

  ],
})
export class EnterNavigation {
  @HostListener("keydown.enter", ['$event'])
  enter(event: KeyboardEvent) {
    (event.srcElement as any).blur();
  }
}

@Directive({
  selector: '[appNoDuplicateTaskNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NoDuplicateTaskNumberValidatorDirective),
      multi: true
    }
  ]
})
export class NoDuplicateTaskNumberValidatorDirective implements AsyncValidator {
  @Input("appNoDuplicateTaskNumber") existingTaskNumber: string;

  constructor(
    protected orders: OrderService,
  ) {

  }

  async validate(control: AbstractControl): Promise<ValidationErrors | null> {
    if (await this.taskNumberExists(control.value) && control.value != this.existingTaskNumber) {
      return { 'taskNumberExists': true };
    }

    return null;
  }

  async taskNumberExists(taskNumber: string) {
    try {
      return await this.orders.taskNumberExists(taskNumber);
    } catch (error) {
    }

    return true;
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, strict: false } },
  ]
})
export class OrderComponent implements OnInit, AfterViewInit {

  @ViewChildren(NgModel) ngModels: QueryList<NgModel>;

  loading = true;

  order: Order;

  consultantControl: FormControl;
  orderIdControl: FormControl;
  leadCreatorControl: FormControl;
  selectedSalesRealizators: FormControl;
  selectedExecutors: FormControl;

  adapter: DateAdapter<any>;

  consultants: Consultant[];

  departmentControl: FormControl;
  departments: Department[];

  existingTaskNumber: string;

  constructor(
    protected dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<OrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected orders: OrderService,
    protected consultantsService: ConsultantService,
    protected departmentsService: DepartmentService,
    protected cd: ChangeDetectorRef,
    protected auth: AuthService,
    protected invoiceService: InvoiceService
  ) {
    this.order = data.order;

    this.order.year = this.order.year ? new Date(new Date(this.order.year).getFullYear(), 0, 1, 12) : new Date();

    if (this.order.id) {
      this.existingTaskNumber = this.order.taskNumber;
    }

    this.consultantControl = this.fb.control(this.order.consultantId);
    this.orderIdControl = this.fb.control(this.order.taskNumber);
    this.orderIdControl.setAsyncValidators(async (control: AbstractControl) => {
      try {
        if (await this.orders.taskNumberExists(control.value) && control.value != this.existingTaskNumber) {
          return { 'taskNumberExists': true };
        }
      } catch (error) {
        console.error(error);
      }
      return null;
    });
    this.departmentControl = this.fb.control(this.order.departmentId);
    this.leadCreatorControl = this.fb.control(this.order.leadCreatorId);
    this.selectedSalesRealizators = this.fb.control(this.order.salesRealizators?.map(_ => _.consultantId) ?? []);
    this.selectedExecutors = this.fb.control(this.order.executors?.map(_ => _.consultantId) ?? []);
  }

  async ngOnInit(): Promise<void> {
    this.consultants = await this.consultantsService.list();
    this.consultants.sort((a, b) => a.initials > b.initials ? 1 : a.initials < b.initials ? -1 : 0);
    this.departments = await this.departmentsService.list();
    this.departments.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);

    this.consultantControl.valueChanges.subscribe(_ => {
      if (this.order.consultantId != _) {
        this.order.consultantId = _;
        setTimeout(async () => {
          let consultant = this.consultants.find(c => c.id == _);

          if (!this.order.year)
            this.order.year = new Date(new Date().getFullYear(), 0, 1, 12);

          if (consultant && !this.order.id) {
            this.orderIdControl.setValue(this.existingTaskNumber = await this.orders.taskNumber(consultant.id, this.order.year));
            this.departmentControl.setValue(consultant.departmentId);
          }
        });
      }
    });

    this.orderIdControl.valueChanges.subscribe(_ => {
      this.order.taskNumber = _;
    });

    this.leadCreatorControl.valueChanges.subscribe(_ => {
      this.order.leadCreatorId = _;
    });

    this.departmentControl.valueChanges.subscribe(_ => {
      this.order.departmentId = _;
    });

    if (!this.order.id) {
      this.consultantControl.setValue(this.consultants.find(_ => _.email == this.auth.activeAccount.username)?.id);
    } else {
      setTimeout(() => {
        this.selectedSalesRealizators.setValue(this.order.salesRealizators?.map(_ => _.consultantId) ?? []);
        this.selectedExecutors.setValue(this.order.executors?.map(_ => _.consultantId) ?? []);
      });

      if (this.order.projectSumExVat && !this.order.expectedInvoice || this.order.expectedInvoice.length == 0)
        this.order.expectedInvoice = [new ExpectedInvoice()];

      this.cd.detectChanges();
    }

    this.loading = false;
    this.cd.detectChanges();

  }

  async ngAfterViewInit(): Promise<void> {

  }

  close() {
    if (window.confirm("Dine ændringer vil ikke blive gemt"))
      this.dialogRef.close();
  }

  numberChanged(prop: string, event: string) {
    if (prop == "pipelinePercentForSuccess" && <unknown>event == 100) {
      // setTimeout(() => {
      this.order.projectSumExVat = null;
      this.order.pipelinePercentForSuccess = null;
      this.order.pipelineProjectSumExVat = null;
      this.order.pipelineWeightedProjectSum = null;
      // });
    } else if (prop != "pipelinePercentForSuccess") {
      let number = event.replace(/\D+/g, '');
      this.order[prop] = number;
    }

    if (prop == "pipelineProjectSumExVat") {
      this.order.expectedInvoice = null;
    } else if (prop == "projectSumExVat") {
      if (!this.order.expectedInvoice)
        this.order.expectedInvoice = [new ExpectedInvoice()];
    }
  }

  resetSums() {
    this.order.projectSumExVat = null;
    this.order.pipelinePercentForSuccess = null;
    this.order.pipelineProjectSumExVat = null;
    this.order.pipelineWeightedProjectSum = null;
  }

  valid() {
    if (!this.order.year || !this.order.consultantId || !this.order.departmentId || !this.order.taskNumber
      || !this.order.customerAndTask)
      return false;

    if (this.order.projectSumExVat) {
      if (this.order.salesRealizators?.length > 0) {
        if (this.order.salesRealizators.map(_ => +_.amount).reduce((prev, curr) => prev + curr, 0) != this.order.projectSumExVat)
          return false;
      } else {
        return false;
      }
      if (this.order.executors?.length > 0) {
        if (this.order.executors.map(_ => +_.amount).reduce((prev, curr) => prev + curr, 0) != this.order.projectSumExVat)
          return false;
      } else {
        return false;
      }
    }

    if (!this.order.projectSumExVat && !this.order.pipelineProjectSumExVat) {
      return false;
    }

    return true;
  }

  consultantAmountError(consultants): any {
    return consultants?.map(_ => +_.amount).reduce((prev, curr) => prev + curr, 0) != this.order.projectSumExVat;
  }

  async save() {
    if (!this.valid()) {
      this.consultantControl.markAllAsTouched();
      this.leadCreatorControl.markAllAsTouched();
      this.selectedSalesRealizators.markAllAsTouched();
      this.selectedExecutors.markAllAsTouched();
      this.ngModels.forEach(_ => _.control.markAllAsTouched());
      return;
    }

    let result = { ...this.order };
    result.invoices = null;

    try {
      await this.orders.save(result);
      this.dialogRef.close(result);
    } catch (error) {
      console.error(error);
    }
  }

  salesRealizator(consultantId: string) {
    if (!this.order.salesRealizators)
      this.order.salesRealizators = [];

    let c = this.order.salesRealizators?.find(_ => _.consultantId == consultantId);

    if (!c) {
      c = new SalesRealizatorAmount(consultantId);
      this.order.salesRealizators.push(c);
    }
    this.order.salesRealizators = this.order.salesRealizators.filter(_ => (this.selectedSalesRealizators.value as string[]).includes(_.consultantId));

    return c;
  }

  percentageChanged(elem: any, s: SalesRealizatorAmount | ExecutorAmount) {
    let percent = elem.value.replace(/,/g, '.');
    let prevValue = +(this.executorPercent(s.consultantId) as number).toFixed(2);
    let result = +((percent / 100) * this.order.projectSumExVat).toFixed(2);

    console.log(prevValue, +percent);

    if (prevValue != +percent) {
      s.amount = result == 0 ? <any>"" : result;
    }
  }

  amountChanged(elem: any, s: SalesRealizatorAmount | ExecutorAmount) {
    let amount = elem.value.replace(/\./g, '').replace(/,/g, '.');
    s.amount = amount == "0" ? <any>"" : +amount;
  }

  salesRealizatorPercent(consultantId: string) {
    let result = (this.salesRealizator(consultantId).amount / this.order.projectSumExVat) * 100;
    return result == 0 ? "" : result;
  }

  // executorPercentageChanged(elem: any, s: ExecutorPercentage) {
  //   s.percentage = (elem.value / this.order.projectSumExVat) * 100;
  // }

  // executorAmountChanged(elem: any, s: ExecutorPercentage) {
  //   s.percentage = elem.value;
  // }

  executorA(consultantId: string) {
    if (!this.order.executors)
      this.order.executors = [];

    let c = this.order.executors?.find(_ => _.consultantId == consultantId);

    if (!c) {
      c = new ExecutorAmount(consultantId);
      this.order.executors.push(c);
    }

    this.order.executors = this.order.executors.filter(_ => (this.selectedExecutors.value as string[]).includes(_.consultantId));

    return c;
  }

  executorPercent(consultantId: string) {
    let result = (this.executorA(consultantId).amount / this.order.projectSumExVat) * 100;
    return result == 0 ? "" : result;
  }

  addExpectedInvoice() {
    this.order.expectedInvoice.push(new ExpectedInvoice());
  }

  deleteExpectedInvoice(idx: number) {
    this.order.expectedInvoice.splice(idx, 1);
  }

  setInvoiceMonthAndYear(ei: ExpectedInvoice, normalizedMonthAndYear: Moment | any, datepicker: MatDatepicker<Date>) {
    if (datepicker) {
      datepicker.close();
    }

    if (normalizedMonthAndYear.value) {
      normalizedMonthAndYear = moment(normalizedMonthAndYear.value, "MM/YYYY", false);
    }

    setTimeout(async () => {
      ei.month = (normalizedMonthAndYear as Moment)?.add(12, "hours").toDate();
      this.cd.detectChanges();
    });
  }

  async setYear(normalizedMonthAndYear: Moment | any, datepicker: MatDatepicker<Date>) {
    if (datepicker) {
      datepicker.close();
    }

    if (normalizedMonthAndYear.value) {
      normalizedMonthAndYear = moment(normalizedMonthAndYear.value, "YYYY", false);
    }


    setTimeout(async () => {
      let year = (normalizedMonthAndYear as Moment).add(12, "hours").toDate();

      if (year.getFullYear() != this.order.year.getFullYear()) {
        this.order.year = year;
        let consultant = this.consultants.find(_ => _.id == this.order.consultantId);
        if (consultant && !this.order.id)
          this.order.taskNumber = await this.orders.taskNumber(consultant.id, this.order.year);
      }
      this.cd.detectChanges();
    });
  }

  initials(consultantId: string) {
    return this.consultants.find(_ => _.id == consultantId).initials;
  }

  openInvoice(invoice: Invoice) {
    let clone = { ...invoice };
    clone.salesRealizatorDistribution = clone.salesRealizatorDistribution.map(_ => ({ ..._ }));
    clone.distribution = clone.distribution.map(_ => ({ ..._ }));
    clone.tasks = clone.tasks.map(_ => ({ ..._ }));
    clone.order = this.order;

    let dialogRef = this.dialog.open(InvoiceComponent, {
      data: { invoice: clone, order: this.order, fromOrder: true },
      disableClose: true,
    });
  }
}

