import { AfterViewInit, Component, Directive, Input, OnInit, ViewChild } from '@angular/core';
import { ConsultantService } from '../services/consultant.service';
import { FormControl } from '@angular/forms';
import { Consultant } from '../models/consultant.model';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { combineLatest } from 'rxjs';
import { OrderService } from '../services/order.service';
import { InvoiceService } from '../services/invoice.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../services/auth.service';
import { MatSort } from '@angular/material/sort';

export const DAY_MONTH_YEAR_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MMMM YYYY',
  },
};
@Directive({
  selector: '[dayMonthYearFormat]',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: DAY_MONTH_YEAR_FORMAT },
  ],
})
export class DayMonthYearFormat {
}

@Component({
  selector: 'app-fee-basis',
  templateUrl: './fee-basis.component.html',
  styleUrl: './fee-basis.component.scss',
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, strict: false } },
  ]
})
export class FeeBasisComponent implements AfterViewInit {
  loading = true;
  consultants: Consultant[];
  consultantControl: FormControl = new FormControl();
  fromControl: FormControl = new FormControl();
  toControl: FormControl = new FormControl();

  displayedColumns: string[] = ['date', 'invoiceId', 'taskNumber', 'customer', 'leadCreator', 'salesRealizator', 'executor', 'sum'];
  @ViewChild(MatTable<any>) table: MatTable<any>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatSort) sorter: MatSort;
  totals: any;

  constructor(
    protected consultantsService: ConsultantService,
    private orderService: OrderService,
    private invoiceService: InvoiceService,
    protected auth: AuthService
  ) {

  }

  async ngAfterViewInit(): Promise<void> {
    this.consultants = await this.consultantsService.list();

    combineLatest({
      consultant: this.consultantControl.valueChanges,
      from: this.fromControl.valueChanges,
      to: this.toControl.valueChanges
    }).subscribe(_ => this.updateData(_.consultant, _.from, _.to));

    this.loading = false;
  }

  async updateData(consult, from, to) {
    const consultant = this.consultants.find(_ => _.id == consult);
    let invoices = await this.invoiceService.feeBasis(consultant?.id, from, to);
    let adjustments = [];
    let rows = invoices.map(invoice => {
      let row: any = {
        date: invoice.date,
        invoiceId: invoice.economicInvoiceNumber,
        taskNumber: invoice.taskNumber,
        customer: invoice.customerName,
      };

      let totalTasks = invoice.tasks.map(_ => _.amount * _.price).reduce((prev, curr) => prev + curr, 0);

      if (consultant.id == invoice.projectLeadId)
        row.leadCreator = 0.05 * totalTasks;

      let salesRealizator = invoice.salesRealizatorDistribution.find(_ => _.consultantId == consultant.id);
      if (salesRealizator) {
        row.salesRealizator = 0.05 * salesRealizator.amount;
      }

      let executor = invoice.distribution?.find(_ => _.consultantId == consultant.id);
      if (executor) {
        row.executor = 0.85 * executor.amount;
      }

      row.sum = (row.leadCreator ?? 0) + (row.salesRealizator ?? 0) + (row.executor ?? 0);

      return row;
    });

    let consultantAdjustments = await this.invoiceService.feeBasisAdjustments(consultant?.id, from, to);
    adjustments.push(consultantAdjustments.map(_ => {
      let e: any = {
        date: _.date,
        invoiceId: _.invoiceNumber,
        taskNumber: _.order.taskNumber,
        customer: _.order.customerAndTask,
        leadCreator: _.leadCreator,
        salesRealizator: _.salesRealizator,
        executor: _.executor,
        sum: (_.leadCreator ?? 0) + (_.salesRealizator ?? 0) + (_.executor ?? 0)
      };
      return e;
    }));

    rows.push(...adjustments.flat());

    this.totals = {
      leadCreator: rows.map(_ => _.leadCreator || 0)?.reduce((prev, curr) => prev + curr, 0),
      salesRealizator: rows.map(_ => _.salesRealizator || 0)?.reduce((prev, curr) => prev + curr, 0),
      executor: rows.map(_ => _.executor || 0)?.reduce((prev, curr) => prev + curr, 0),
    };
    this.totals.sum = this.totals.leadCreator + this.totals.salesRealizator + this.totals.executor;

    this.dataSource = new MatTableDataSource(rows);
    this.dataSource.sort = this.sorter;
    this.sorter.sort({ disableClear: false, id: "date", start: "desc" });
  }

  async exportPdf() {
    let file = await this.invoiceService.feeBasisPdf(this.consultantControl.value, this.fromControl.value, this.toControl.value);
    const blob = new Blob([file.body], { type: `application/pdf;` });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `honorargrundlag_${this.consultants.find(_ => _.id == this.consultantControl.value)?.initials}.pdf`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async exportExcel() {
    let file = await this.invoiceService.feeBasisExcel(this.consultantControl.value, this.fromControl.value, this.toControl.value);
    const blob = new Blob([file.body], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;` });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `honorargrundlag_${this.consultants.find(_ => _.id == this.consultantControl.value)?.initials}.xlsx`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}
