import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { TableColumnPickerService } from '../services/table-column-picker.service';
import { ColumnOption } from '../models/column-option';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-table-column-picker',
  templateUrl: './table-column-picker.component.html',
  styleUrls: ['./table-column-picker.component.scss']
})
export class TableColumnPickerComponent implements OnInit {

  @Output() displayedColumnsChange = new EventEmitter<any[]>();

  columnOptions: ColumnOption[] = []
  formControl = new FormControl([]);
  columnPickerService: TableColumnPickerService = inject(TableColumnPickerService);

  constructor() { }

  ngOnInit(): void {
    this.columnOptions = this.columnPickerService.getOptions();


    this.formControl.valueChanges.subscribe(displayedColumns => {
      this.displayedColumnsChange.emit(displayedColumns);
    });

    this.formControl.setValue(this.extractSelectionValues(this.columnOptions));


  }

  extractSelectionValues(input: ColumnOption[]): string[] {
    let result: string[] = [];

    input.forEach((column) => {
      result.push(column.value);
    })

    return result;
  }

  selectAllColumns(): void {
    this.formControl.setValue(this.extractSelectionValues(this.columnOptions));
  }

  deselectAllColumns(): void {
    this.formControl.setValue([]);
  }
}
