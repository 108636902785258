import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { Order } from '../models/order.model';
import { DecimalPipe } from '@angular/common';
import { NgModel } from '@angular/forms';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(
    private decimalPipe:DecimalPipe
  ) {

  }

  transform(value: string, digitsInfo = "1.0-0"): string {
    return this.decimalPipe.transform(value.replace(",","."), digitsInfo, "da");
  }

  parse(value: string): string {
    return value.replaceAll(".","");//.replace(",",".");
  }

}

@Directive({ selector: "[currencyFormat]" })
export class CurrencyFormatterDirective implements OnInit, AfterViewInit, OnChanges, AfterContentInit, AfterViewChecked, AfterContentChecked {
  @Input("currencyFormat") digitsInfo: string;

  private el: HTMLInputElement;

  constructor(
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef,
    private model: NgModel,
    private currencyPipe: CurrencyFormatPipe
  ) {
    this.el = this.elementRef.nativeElement;
    model.options = {
      standalone: true,
      updateOn: 'submit'
    };
  }
  ngAfterContentChecked(): void {
  }
  ngAfterViewChecked(): void {
  }
  ngAfterContentInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    this.el.value = ""+this.currencyPipe.parse(value);
    console.log("onFocus", value);
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.model.update.emit(value.replaceAll(".","").replace(",","."));
    setTimeout(() => {
      this.el.value = this.currencyPipe.transform(value, this.digitsInfo) || null;
    });
    console.log("onBlur", value);
    return true;
  }

}
