@if (!loading) {
<mat-form-field appearance="outline" style="width:150px">
  <mat-label>Transport takst</mat-label>
  <input matInput type="text" [ngModel]="settings.transportFee | number: '1.0-2' : 'da'"
    (blur)="updateFee($event.srcElement)">
</mat-form-field>

<div>
  <mat-form-field appearance="outline" style="width:300px">
    <mat-label>Economic API Key</mat-label>
    <input matInput type="text" [(ngModel)]="settings.economicApiKey">
  </mat-form-field>
  <a [href]="economicInstallUrl" target="_blank" style="margin-left:1rem;">Giv adgang til economic</a>
</div>

<h3>Economic produkter</h3>

<div style="display: flex;flex-direction: column;">
  @for (item of settings?.products; track $index) {
  <div style="display: flex;gap:1rem;">
    <mat-form-field appearance="outline" style="width:200px">
      <mat-label>Produkt nummer (fra economic)</mat-label>
      <input matInput type="text" [(ngModel)]="item.number">
    </mat-form-field>
    <mat-form-field appearance="outline" style="flex:1">
      <mat-label>Navn</mat-label>
      <input matInput type="text" [(ngModel)]="item.name">
    </mat-form-field>
    <button mat-icon-button (click)="removeProduct($index)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  }
</div>

<button mat-raised-button color="primary" style="width: fit-content;" (click)="addProduct()">
  <mat-icon>add</mat-icon>
  Tilføj product
</button>

<button mat-raised-button color="primary" style="width: fit-content;" (click)="save()">
  Gem
</button>
}