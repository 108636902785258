import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Department } from 'src/app/models/department.model';
import { DepartmentComponent } from './department/department.component';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {
  displayedColumns: string[] = ['actions', 'name' ];
  @ViewChild(MatTable<Department>) table: MatTable<Department>;
  dataSource: MatTableDataSource<Department>;

  constructor(
    protected dialog: MatDialog,
    protected departments:DepartmentService
  ) {
  
  }

  async ngOnInit(): Promise<void> {
    this.dataSource = new MatTableDataSource(await this.departments.list());
  }

  createDepartment() {
    let dialogRef = this.dialog.open(DepartmentComponent, {
      width: '50%',
      data: { department: new Department() },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(created => {
      if (created) {
        this.dataSource.data.push(created);
        this.table.renderRows();
      }
    });
  }

  openDepartment(department: Department) {
    let dialogRef = this.dialog.open(DepartmentComponent, {
      width: '50%',
      data: { department: department },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(updated => {
      if (updated) {
        let idx = this.dataSource.data.findIndex(_ => _.id == updated.id);
        this.dataSource.data[idx] = updated;
        this.table.renderRows();
      }
    });
  }
}
