@if (!loading) {
<div style="display: flex;gap: 1rem;">
  <mat-form-field appearance="outline">
    <mat-label>Opgave ID</mat-label>
    <input type="text" matInput [formControl]="orderControl" [matAutocomplete]="autoOrders" required>
    <mat-autocomplete #autoOrders="matAutocomplete" [displayWith]="orderDisplayFn">
      @for (option of filteredOrders | async; track option) {
      <mat-option [value]="option">{{option.taskNumber}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 350px;">
    <mat-label>Fakturadato (DD/MM/ÅÅÅÅ)</mat-label>
    <input matInput [matDatepicker]="dp" [formControl]="dateControl" (dateChange)="dateSelected($event, dp)" required>
    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp>
    </mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Lead Creator</mat-label>
    <input type="text" matInput [formControl]="leadCreatorControl" [matAutocomplete]="autoLeadCreators" required>
    <mat-autocomplete #autoLeadCreators="matAutocomplete" [displayWith]="consultantService.displayFn">
      @for (option of filteredLeadCreators | async; track option) {
      <mat-option [value]="option">{{option.initials}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</div>

<mat-form-field appearance="outline">
  <mat-label>Kunde/opgave</mat-label>
  <textarea matInput [ngModel]="order?.customerAndTask"></textarea>
</mat-form-field>

<div style="display: flex;gap: 1rem;">
  <mat-form-field appearance="outline" style="flex: 1">
    <mat-label>Faktura nr.</mat-label>
    <input matInput type="text" [(ngModel)]="invoice.economicInvoiceNumber" required enterNavigation>
  </mat-form-field>
  <mat-form-field appearance="outline" style="flex: 1">
    <mat-label>Faktura beløb</mat-label>
    <input matInput type="text" #invoiceAmountModel="ngModel" [ngModel]="invoiceAmount | number:'1.0-2':'da'"
      (ngModelChange)="invoiceAmountSet($event)" required enterNavigation currencyFormat="1.0-2">
  </mat-form-field>
</div>

<div style="flex:1;display: flex;gap: 1rem;" [style.visibility]="invoiceAmount ? 'visible' : 'hidden'">
  <app-autocomplete-chips style="flex:1;" [formControl]="salesRealizatorControl" label="Salgsrealisator"
    [displayFn]="consultantService.displayFn" [valueFn]="consultantService.valueFn" [options]="consultants"
    [required]="true"></app-autocomplete-chips>
  <app-autocomplete-chips style="flex:1;" [formControl]="executorControl" label="Udførende"
    [displayFn]="consultantService.displayFn" [valueFn]="consultantService.valueFn" [options]="consultants"
    [required]="true"></app-autocomplete-chips>
</div>
@if(invoiceAmount) {
@if (invoice.leadCreatorDistribution) {
<h3>Lead creator</h3>
<div style="flex:1;display: flex;gap: 1rem;">
  <mat-form-field appearance="outline">
    <mat-label>Lead creator</mat-label>
    <input matInput type="text" [value]="consultant(invoice.leadCreatorDistribution.consultantId)?.initials" readonly>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Lead creator kr.</mat-label>
    <input matInput type="text" [ngModel]="invoice.leadCreatorDistribution.amount | number:'1.0-2':'da'"
      (ngModelChange)="invoice.leadCreatorDistribution.amount = $event" required enterNavigation currencyFormat="1.0-2">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Lead creator %</mat-label>
    <input matInput type="text"
      [ngModel]="(invoice.leadCreatorDistribution.amount / (invoiceAmount - totalExpenses())) * 100 || 100 | number:'1.0-2':'da'"
      (ngModelChange)="updateAmount(invoice.leadCreatorDistribution, $event)" required enterNavigation
      currencyFormat="1.0-2">
    @if (invoice.leadCreatorDistribution.amount) {
    <span matTextSuffix>&nbsp;%</span>
    }
  </mat-form-field>
</div>
}
<h3>Salgsrealisatorer</h3>
@for (salesrealizer of invoice.salesRealizatorDistribution; track salesrealizer) {
<div style="flex:1;display: flex;gap: 1rem;">
  <mat-form-field appearance="outline">
    <mat-label>Salgsrealisator</mat-label>
    <input matInput type="text" [value]="consultant(salesrealizer.consultantId).initials" readonly>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Salgsrealisator kr.</mat-label>
    <input matInput type="text" [ngModel]="salesrealizer.amount | number:'1.0-2':'da'"
      (ngModelChange)="salesrealizer.amount = $event" required enterNavigation currencyFormat="1.0-2">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Salgsrealisator %</mat-label>
    <input matInput type="text"
      [ngModel]="(salesrealizer.amount / (invoiceAmount - totalExpenses())) * 100 || null | number:'1.0-2':'da'"
      (ngModelChange)="updateAmount(salesrealizer, $event)" required enterNavigation currencyFormat="1.0-2">
    @if (salesrealizer.amount) {
    <span matTextSuffix>&nbsp;%</span>
    }
  </mat-form-field>
</div>
}
@if (salesRealizatorAmountError() && invoice.salesRealizatorDistribution?.length > 0) {
<h3 style="color:red">Summen af beløbene skal
  være lig med 'Fakturabeløb' fratrukket alle udlæg</h3>
}
<h3>Udførende</h3>
@for (executor of invoice.distribution; track executor) {
<div style="flex:1;display: flex;gap: 1rem;">
  <mat-form-field appearance="outline">
    <mat-label>Udførende</mat-label>
    <input matInput type="text" [value]="consultant(executor.consultantId).initials" readonly>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Udførende kr.</mat-label>
    <input matInput type="text" [ngModel]="executor.amount | number:'1.0-2':'da'"
      (ngModelChange)="executor.amount = $event" required enterNavigation currencyFormat="1.0-2">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Udførende %</mat-label>
    <input matInput type="text"
      [ngModel]="(executor.amount / (invoiceAmount - totalExpenses())) * 100 || null | number:'1.0-2':'da'"
      (ngModelChange)="updateAmount(executor, $event)" required enterNavigation currencyFormat="1.0-2">
    @if (executor.amount) {
    <span matTextSuffix>&nbsp;%</span>
    }
  </mat-form-field>
</div>
}
@if (executorAmountError() && invoice.distribution?.length > 0) {
<h3 style="color:red">Summen af beløbene skal
  være lig med 'Fakturabeløb'</h3>
}
} @else if (invoiceAmountModel.touched) {
<h3 style="color:red">Indtast 'Fakturabeløb'</h3>
}

<mat-checkbox [checked]="order?.invoicingFinished" (change)="order.invoicingFinished = $event.checked">Færdig
  faktureret</mat-checkbox>

<div style="display: flex;gap: 1rem;margin-top: 1rem;">
  <button mat-raised-button color="primary" style="width: fit-content;" (click)="save()">
    Bogfør
  </button>

  <button mat-raised-button color="primary" style="width: fit-content;" (click)="reset()">
    Nulstil
  </button>
</div>
}