<button mat-raised-button color="primary" style="width: fit-content;" (click)="createDepartment()">
  <mat-icon>add</mat-icon> Opret ny forretningsområde
</button>

<div class="table-container">

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="openDepartment(element)" matTooltip="Rediger">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Navn </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>