import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings } from 'src/app/models/settings.model';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  loading = true;
  settings: Settings;
  economicInstallUrl: any = environment.economicInstallUrl

  constructor(
    private snackBar: MatSnackBar,
    private settingsService: SettingsService
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.settings = await this.settingsService.get() ?? new Settings(null, null);
    this.loading = false;
  }

  async save() {
    try {
      await this.settingsService.save(this.settings);
      this.snackBar.open("Indstillingerne er gemt");
    } catch (error: any) {
      this.snackBar.open(error.message);
    }
  }

  updateFee(elem) {
    let percent = elem.value.replace(/,/g, '.');
    this.settings.transportFee = +percent;
  }

  addProduct() {
    if (!this.settings.products) {
      this.settings.products = [];
    }

    this.settings.products.push({ name: '', number: '' });
  }

  removeProduct(index: number) {
    this.settings.products.splice(index, 1);
  }
}
