import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { Consultant } from '../models/consultant.model';
import { ConsultantSummary } from '../models/consultant-summary.model';
import { ConsultantSummaryDetails } from '../models/consultant-summary-details.model';

@Injectable({
  providedIn: 'root'
})
export class ConsultantService extends BaseHttpService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public displayFn(consultant: Consultant): string {
    return consultant && consultant.initials ? consultant.initials : '';
  }

  public valueFn(consultant: Consultant): string {
    return consultant && consultant.id ? consultant.id : '';
  }

  public list() {
    return firstValueFrom(this.http.get<Consultant[]>(`${this.baseApiUrl}/consultant/list`));
  }

  public exists(initials: string) {
    return firstValueFrom(this.http.get<boolean>(`${this.baseApiUrl}/consultant/exists`, { params: { initials: initials } }));
  }

  public get(id: string) {
    return firstValueFrom(this.http.get<Consultant>(`${this.baseApiUrl}/consultant/${id}`));
  }

  public canDelete(id: string) {
    return firstValueFrom(this.http.get<boolean>(`${this.baseApiUrl}/consultant/can-delete/${id}`));
  }

  public delete(id: string) {
    return firstValueFrom(this.http.delete<boolean>(`${this.baseApiUrl}/consultant/${id}`));
  }

  public save(consultant: Consultant) {
    return firstValueFrom(this.http.post<Consultant>(`${this.baseApiUrl}/consultant/save`, consultant));
  }

  public summary(year: number) {
    return firstValueFrom(this.http.get<ConsultantSummary[]>(`${this.baseApiUrl}/consultant/summary/${year}`));
  }

  public summaryDetails(consultantId: string, year: number) {
    return firstValueFrom(this.http.get<ConsultantSummaryDetails>(`${this.baseApiUrl}/consultant/summary-details/${year}?consultantId=${consultantId}`));
  }

  summaryPdf(year: number) {
    return firstValueFrom(this.http.get(`${this.baseApiUrl}/consultant/summary-pdf`,
      {
        observe: "response", responseType: "blob",
        params: { year: year }
      }));
  }
  summaryExcel(year: number) {
    return firstValueFrom(this.http.get(`${this.baseApiUrl}/consultant/summary-excel`,
      {
        observe: "response", responseType: "blob",
        params: { year: year }
      }));
  }
}