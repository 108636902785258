import { BaseModel } from "./base.model";

export class Department extends BaseModel {

constructor(
    public name?: string,
) {
    super();
    
}
}