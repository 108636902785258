<div style="display: flex;flex-direction: row;">
  <h2 mat-dialog-title style="display: inline;flex: auto;">Projekter</h2>
  <button mat-button (click)="close()">X</button>
</div>
<mat-dialog-content>
  @if (!loading) {
  <div>
    <div class="form-row">
      <mat-form-field appearance="outline" yearFormat style="flex:1">
        <mat-label>År (ÅÅÅÅ)</mat-label>
        <input matInput [matDatepicker]="dpYear" [ngModel]="order.year" (blur)="setYear($event.srcElement, null)"
          required enterNavigation>
        <mat-datepicker-toggle matIconSuffix [for]="dpYear"></mat-datepicker-toggle>
        <mat-datepicker #dpYear startView="multi-year" (yearSelected)="setYear($event, dpYear)">
        </mat-datepicker>
      </mat-form-field>
      <div class="form-row">
        <app-autocomplete-chips [formControl]="consultantControl" label="Konsulent/projektejer"
          [displayFn]="consultantsService.displayFn" [valueFn]="consultantsService.valueFn" [options]="consultants"
          [multiple]="false" [required]="true"></app-autocomplete-chips>
      </div>
      <div class="form-row">
        <app-autocomplete-chips [formControl]="departmentControl" label="Forretningsområde"
          [displayFn]="departmentsService.displayFn" [valueFn]="departmentsService.valueFn" [options]="departments"
          [multiple]="false" [required]="true"></app-autocomplete-chips>
      </div>
      <mat-form-field appearance="outline" style="flex:1;">
        <mat-label>Opgave id</mat-label>
        <input matInput type="text" [formControl]="orderIdControl" required enterNavigation>
        @if (orderIdControl.errors?.["taskNumberExists"]) {
        <mat-error>Opgave id'et eksisterer allerede</mat-error>
        }
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Kunde/opgave</mat-label>
      <textarea matInput [(ngModel)]="order.customerAndTask" required #customerAndTask="ngModel"></textarea>
    </mat-form-field>
    <!-- <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Economic-kunde nr.</mat-label>
        <input matInput type="text" [(ngModel)]="order.economicCustomerNumber" enterNavigation
          [disabled]="uknownCheckbox.checked">
      </mat-form-field>
      <mat-checkbox style="flex: 200px;margin-top: 7px;" #uknownCheckbox
        (change)="$event ? order.economicCustomerNumber = '999' : order.economicCustomerNumber = ''">Ukendt
        (999)</mat-checkbox>
    </div> -->
    <h3>Vælg projektsum for projekt eller pipeline*</h3>
    <div class="form-row">
      @if ((!order.pipelineProjectSumExVat && !order.pipelinePercentForSuccess && !order.pipelineWeightedProjectSum)) {
      <mat-form-field appearance="outline">
        <mat-label>Projektsum Ex. Moms</mat-label>
        <input matInput type="text" [ngModel]="order.projectSumExVat | number: '1.0-0' : 'da'"
          (ngModelChange)="numberChanged('projectSumExVat', $event)" [ngModelOptions]="{updateOn:'blur'}"
          enterNavigation>
      </mat-form-field>
      }
      @if (!order.projectSumExVat) {
      <mat-form-field appearance="outline">
        <mat-label>Pipeline projektsum ex. moms</mat-label>
        <input matInput type="text" [ngModel]="order.pipelineProjectSumExVat | number: '1.0-0' : 'da'"
          (ngModelChange)="numberChanged('pipelineProjectSumExVat', $event)" [ngModelOptions]="{updateOn:'blur'}"
          enterNavigation>
      </mat-form-field>
      }
    </div>
    @if (!order.projectSumExVat) {
    <div class="form-row">
      <!-- <mat-form-field appearance="outline">
                    <mat-label>Pipeline % for win</mat-label>
                    <input matInput type="number" [(ngModel)]="order.pipelinePercentForSuccess">
                    <span matTextSuffix *ngIf="order.pipelinePercentForSuccess">&nbsp;%</span>
                  </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label>Pipeline % for win*</mat-label>
        <mat-select [(ngModel)]="order.pipelinePercentForSuccess"
          (ngModelChange)="numberChanged('pipelinePercentForSuccess', $event)">
          <mat-option [value]="100">100%</mat-option>
          <mat-option [value]="80">80%</mat-option>
          <mat-option [value]="50">50%</mat-option>
          <mat-option [value]="25">25%</mat-option>
          <mat-option [value]="10">10%</mat-option>
          <mat-option [value]="0">0%</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Pipeline Vægtet Projektsum*</mat-label>
        <input matInput type="text"
          [ngModel]="(order.pipelinePercentForSuccess / 100) * order.pipelineProjectSumExVat || '' | number: '1.0-2' : 'da'"
          readonly>
      </mat-form-field>
    </div>
    }
    <button mat-raised-button color="primary" (click)="resetSums()" style="margin-bottom: 1rem;">Nulstil beløb</button>
    <app-autocomplete-chips [formControl]="leadCreatorControl" label="Lead Creator"
      [displayFn]="consultantsService.displayFn" [valueFn]="consultantsService.valueFn" [options]="consultants"
      [multiple]="false" [required]="true"></app-autocomplete-chips>
    <mat-divider></mat-divider>
    @if (!order.pipelineProjectSumExVat && !order.pipelinePercentForSuccess && !order.pipelineWeightedProjectSum) {
    <app-autocomplete-chips debug="selectedSalesRealizators" [formControl]="selectedSalesRealizators"
      label="Salgsrealisatorer" [displayFn]="consultantsService.displayFn" [valueFn]="consultantsService.valueFn"
      [options]="consultants" [required]="true"></app-autocomplete-chips>
    @for (salesrealizer of selectedSalesRealizators.value; track salesrealizer) {
    <div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label>Konsulent</mat-label>
          <input matInput type="text" [value]="initials(salesrealizer)" readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Salgsrealisator kr.</mat-label>
          <input matInput type="text" [ngModel]="salesRealizator(salesrealizer).amount | number: '1.0-2' : 'da'"
            (blur)="amountChanged($event.srcElement, salesRealizator(salesrealizer))" required enterNavigation>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Salgsrealisator %</mat-label>
          <input matInput type="text" [ngModel]="salesRealizatorPercent(salesrealizer) | number: '1.0-2' : 'da'"
            (blur)="percentageChanged($event.srcElement, salesRealizator(salesrealizer))" required enterNavigation>
          @if (salesRealizator(salesrealizer).amount) {
          <span matTextSuffix>&nbsp;%</span>
          }
        </mat-form-field>

      </div>
    </div>
    }
    @if (consultantAmountError(order.salesRealizators) && order.salesRealizators?.length > 0) {
    <h3 style="color:red">Summen af beløbene
      skal være lig med 'Projektsum Ex. Moms'</h3>
    }
    }
    <mat-divider></mat-divider>
    @if (!order.pipelineProjectSumExVat && !order.pipelinePercentForSuccess && !order.pipelineWeightedProjectSum) {
    <app-autocomplete-chips [formControl]="selectedExecutors" label="Udførende"
      [displayFn]="consultantsService.displayFn" [valueFn]="consultantsService.valueFn" [options]="consultants"
      [required]="true"></app-autocomplete-chips>
    @for (executor of selectedExecutors.value; track executor) {
    <div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label>Konsulent/projektejer</mat-label>
          <input matInput type="text" [value]="initials(executor)" readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Udførende kr.</mat-label>
          <input matInput type="text" [ngModel]="executorA(executor).amount | number: '1.0-2' : 'da'"
            (blur)="amountChanged($event.srcElement, executorA(executor))" required enterNavigation>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Udførende %</mat-label>
          <input matInput type="text" [ngModel]="executorPercent(executor) | number: '1.0-2' : 'da'"
            (blur)="percentageChanged($event.srcElement, executorA(executor))" required enterNavigation>
          @if (executorA(executor).amount) {
          <span matTextSuffix>&nbsp;%</span>
          }
        </mat-form-field>
      </div>
    </div>
    }
    @if (consultantAmountError(order.executors) && order.executors?.length > 0) {
    <h3 style="color:red">Summen af beløbene skal
      være lig med 'Projektsum Ex. Moms'</h3>
    }
    }

    @if (order.projectSumExVat > 0) {
    <h3>Forventet fakturering</h3>
    @for (ei of order.expectedInvoice; track ei; let i = $index) {
    <div class="form-row">
      <mat-form-field appearance="outline" monthYearFormat>
        <mat-label>Forventet faktura måned (MM/ÅÅÅÅ)</mat-label>
        <input matInput [matDatepicker]="dp" [ngModel]="ei.month"
          (blur)="setInvoiceMonthAndYear(ei, $event.srcElement, null)" enterNavigation #invoiceMonth="ngModel">
        <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (monthSelected)="setInvoiceMonthAndYear(ei, $event, dp)">
        </mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Beløb</mat-label>
        <input matInput type="text" currencyFormat="1.0-2" [(ngModel)]="ei.amount" enterNavigation>
      </mat-form-field>
      <button mat-icon-button (click)="deleteExpectedInvoice(i)" matTooltip="Slet">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    }
    <div class="form-row"><button mat-raised-button color="primary" style="width: fit-content;margin-bottom: 1rem;"
        (click)="addExpectedInvoice()">Tilføj</button>
      <mat-checkbox [checked]="order.invoicingFinished" (change)="order.invoicingFinished = $event.checked">Færdig
        faktureret</mat-checkbox>
    </div>
    @if (order.invoices?.length > 0) {
    Fakturaer
    <table style="width:300px;">
    @for (invoice of order.invoices; track $index) {
    <tr>
      <td><u><a style="cursor: pointer;" (click)="openInvoice(invoice)">{{ invoice.economicInvoiceNumber }}</a> </u></td>
      <td>{{ invoice.date | date: 'dd/MM/yyyy' }}</td>
      <td>{{ invoiceService.total(invoice) | currency: 'DKK' }}</td>
    </tr>
    }
    </table>
  }
    }
    <mat-form-field appearance="outline">
      <mat-label>Kommentar</mat-label>
      <textarea matInput [(ngModel)]="order.comment"></textarea>
    </mat-form-field>
  </div>
  }

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="close()">Annuller</button>
  <button mat-raised-button color="primary" (click)="save()">Gem</button>
</mat-dialog-actions>