@if (!loading) {
<div style="display: flex;gap: 1rem;">
  <app-autocomplete-chips [formControl]="consultantControl" label="Projektejer"
    [displayFn]="consultantsService.displayFn" [valueFn]="consultantsService.valueFn" [options]="consultants"
    [multiple]="false"></app-autocomplete-chips>

  <app-autocomplete-chips [formControl]="departmentControl" label="Forretningsområde" [displayFn]="departmentsService.displayFn"
    [valueFn]="departmentsService.valueFn" [options]="departments" [multiple]="false"></app-autocomplete-chips>

  <mat-form-field appearance="outline">
    <mat-label>Søg</mat-label>
    <input matInput type="text" [formControl]="taskNumberControl">
  </mat-form-field>

  <mat-checkbox style="margin-top: 7px;" [formControl]="showManualControl">Vis manuelt oprettede</mat-checkbox>
</div>

<div style="width:1400px">
  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="openInvoice(element)" matTooltip="Åben">
          <mat-icon>description</mat-icon>
        </button>
        @if (element.status != 1) {
        <button mat-icon-button (click)="removeInvoice(element)" matTooltip="Slet">
          <mat-icon>delete</mat-icon>
        </button>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="economicInvoiceNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Faktura nr. </th>
      <td mat-cell *matCellDef="let element"> {{element?.economicInvoiceNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Faktureringsdato </th>
      <td mat-cell *matCellDef="let element"> {{ element?.date | date:'dd/MM/yyyy'}} </td>
    </ng-container>
    <ng-container matColumnDef="taskNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Opgave ID </th>
      <td mat-cell *matCellDef="let element"> {{element?.taskNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="projectLead">
      <th mat-header-cell *matHeaderCellDef> Projektejer </th>
      <td mat-cell *matCellDef="let element"> {{consultant(element?.order.consultantId)?.initials}} </td>
    </ng-container>
    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Forretningsområde </th>
      <td mat-cell *matCellDef="let element"> {{department(element?.departmentId)}} </td>
    </ng-container>
    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kunde og opgave </th>
      <td mat-cell *matCellDef="let element"> {{element?.customerName}} </td>
    </ng-container>
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total ex. moms </th>
      <td mat-cell *matCellDef="let element" class="right-align"> {{ invoiceService.total(element) | number:'1.2-2' }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>


}