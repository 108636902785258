<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="'over'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar color="primary" [class.staging]="isStaging()">
      <img src="assets/logo-600.webp" height="40px">
    </mat-toolbar>
    <mat-nav-list>
      @if (auth.activeAccount && (auth.isAdmin() || auth.isConsultant())) {
      <span style="margin-left: 1rem;margin-bottom: 1rem;">{{ auth.activeAccount?.name }}</span>
      <!-- <span style="margin: 0 1rem;"></span> -->
      <a mat-list-item routerLink="orders">Projekter</a>
      <a mat-list-item routerLink="pivot">Rapport</a>
      <a mat-list-item routerLink="consultant-summary">Totaler</a>
      <a mat-list-item routerLink="invoices">Fakturaer</a>
      <a mat-list-item routerLink="fee-basis">Honorargrundlag</a>
      @if (auth.isAdmin()) {
      <a mat-list-item routerLink="admin">Administration</a>
      }
      }
      @if(this.auth.activeAccount) {
      <a mat-list-item (click)="logout()">Log ud</a>
      }
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" [class.staging]="isStaging()">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      @if (!drawer.opened) {
      <img src="assets/logo-600.webp" height="40px">
      }
    </mat-toolbar>
    @if (!isIframe) {
    <router-outlet></router-outlet>
    }
  </mat-sidenav-content>
</mat-sidenav-container>