import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { Invoice } from '../models/order.model';
import { Settings } from '../models/settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseHttpService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public get() {
    return firstValueFrom(this.http.get<Settings>(`${this.baseApiUrl}/settings`));
  }

  public save(settings: Settings) {
    return firstValueFrom(this.http.post<Settings>(`${this.baseApiUrl}/settings/save`, settings));
  }
}