<mat-tab-group>
    <mat-tab label="Konsulenter">
        <ng-template matTabContent="">
            <app-consultants></app-consultants>
        </ng-template>
    </mat-tab>
    <mat-tab label="Forretningsområder">
        <ng-template matTabContent="">
            <app-departments></app-departments>
        </ng-template>
    </mat-tab>
    <mat-tab label="Manuel fakturering">
        <ng-template matTabContent="">
            <app-manual-invoicing></app-manual-invoicing>
        </ng-template>regulering honorargrundlag
    </mat-tab>
    <mat-tab label="Regulering Honorargrundlag">
        <ng-template matTabContent="">
            <app-fee-basis-adjustment></app-fee-basis-adjustment>
        </ng-template>
    </mat-tab>
    <mat-tab label="Indstillinger">
        <ng-template matTabContent="">
            <app-settings></app-settings>
        </ng-template>
    </mat-tab>
    <mat-tab label="Audit log">
        <ng-template matTabContent="">
            <app-audit></app-audit>
        </ng-template>
    </mat-tab>
</mat-tab-group>