import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { Invoice } from '../models/order.model';
import { FeeBasisAdjustment } from '../models/fee-basis-adjustment.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseHttpService {
  
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public total(invoice: Invoice) {
    let total = 0;

    total += invoice.tasks?.map(_ => _.amount * _.price).reduce((prev, curr) => prev + curr, 0) || 0;

    return total;
  }

  totalIncludingExpenses(invoice: Invoice): string | number {
    let total = 0;

    total += invoice.tasks?.map(_ => _.amount * _.price).reduce((prev, curr) => prev + curr, 0) || 0;
    total += invoice.expenses?.map(_ => _.price).reduce((prev, curr) => prev + curr, 0) || 0;
    total += invoice.transport?.map(_ => _.amount * _.price).reduce((prev, curr) => prev + curr, 0) || 0;

    return total;
  }

  public feeBasis(consultant: string, from: Date, to: Date) {
    return firstValueFrom(this.http.get<Invoice[]>(`${this.baseApiUrl}/invoice/fee-basis`,
      { params: { consultant: consultant, from: from?.toISOString(), to: to?.toISOString() } }));
  }

  public feeBasisAdjustments(consultant: string, from: Date, to: Date) {
    return firstValueFrom(this.http.get<FeeBasisAdjustment[]>(`${this.baseApiUrl}/invoice/fee-basis-adjustments`,
      { params: { consultant: consultant, from: from?.toISOString(), to: to?.toISOString() } }));
  }

  public list() {
    return firstValueFrom(this.http.get<Invoice[]>(`${this.baseApiUrl}/invoice/list`));
  }

  public get(id: number) {
    return firstValueFrom(this.http.get<Invoice>(`${this.baseApiUrl}/invoice/${id}`));
  }

  public draft(id: string) {
    return firstValueFrom(this.http.get<boolean>(`${this.baseApiUrl}/invoice/draft/${id}`));
  }

  public save(invoice: Invoice, invoicingFinished?: boolean) {
    return firstValueFrom(this.http.post<Invoice>(`${this.baseApiUrl}/invoice/save?invoicingFinished=${invoicingFinished}`, invoice));
  }

  public delete(id: string) {
    return firstValueFrom(this.http.delete<boolean>(`${this.baseApiUrl}/invoice/${id}`));
  }

  feeBasisExcel(consultantId: string, from: Date, to: Date) {
    return firstValueFrom(this.http.get(`${this.baseApiUrl}/invoice/fee-basis-excel`,
      {
        observe: "response", responseType: "blob",
        params: { consultant: consultantId, from: from?.toISOString(), to: to?.toISOString() }
      }));
  }

  feeBasisPdf(consultantId: string, from: Date, to: Date) {
    return firstValueFrom(this.http.get(`${this.baseApiUrl}/invoice/fee-basis-pdf`,
      {
        observe: "response", responseType: "blob",
        params: { consultant: consultantId, from: from?.toISOString(), to: to?.toISOString() }
      }));
  }
}