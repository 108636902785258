import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Moment } from 'moment';
import { Observable, map, startWith } from 'rxjs';
import { DDMMYYYY_FORMAT } from 'src/app/invoices/invoice/invoice.component';
import { Consultant, DistributionAmount } from 'src/app/models/consultant.model';
import { Invoice, InvoiceExpense, InvoiceStatus, InvoiceTask, Order } from 'src/app/models/order.model';
import { Settings } from 'src/app/models/settings.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { OrderService } from 'src/app/services/order.service';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';

export declare type OrderId = {
  taskNumber: string;
  orderId: string;
};

@Component({
  selector: 'app-manual-invoicing',
  templateUrl: './manual-invoicing.component.html',
  styleUrls: ['./manual-invoicing.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: DDMMYYYY_FORMAT },
  ]
})
export class ManualInvoicingComponent implements OnInit, AfterViewInit {
  @Input() invoice: Invoice;
  @Input() order: Order;
  @Input() fromOrder: boolean;
  @ViewChildren(NgModel) ngModels: QueryList<NgModel>;

  @Output() close = new EventEmitter<boolean>();

  loading = true;
  settings: Settings;


  formGroup: FormGroup;
  orderIds: OrderId[];
  orderControl = new FormControl();
  filteredOrders: Observable<OrderId[]>;
  dateControl = new FormControl();
  consultants: Consultant[];
  leadCreatorControl = new FormControl();
  filteredLeadCreators: Observable<Consultant[]>;
  salesRealizatorControl = new FormControl();
  executorControl = new FormControl();

  invoiceAmount: number;
  leadCreator: DistributionAmount;

  constructor(
    private snackBar: MatSnackBar,
    private settingsService: SettingsService,
    private orderService: OrderService,
    protected consultantService: ConsultantService,
    private invoiceService: InvoiceService
  ) {
    this.formGroup = new FormGroup({
      order: this.orderControl,
      date: this.dateControl,
      leadCreator: this.leadCreatorControl,
      salesRealizator: this.salesRealizatorControl,
      executor: this.executorControl
    });
  }


  async ngOnInit(): Promise<void> {

  }

  async ngAfterViewInit(): Promise<void> {
    this.settings = await this.settingsService.get() ?? new Settings(null, null);
    this.orderIds = await this.orderService.taskNumbers();
    this.consultants = await this.consultantService.list();

    if (this.fromOrder) {
      this.invoice.order = this.order;
    }

    if (this.invoice?.order) {
      this.order = this.invoice.order;
      setTimeout(() => {
        this.formGroup.patchValue({ date: this.invoice.date ?? new Date() });
      });
      this.invoiceAmount = this.invoice.tasks?.map(_ => _.amount * _.price).reduce((prev, curr) => prev + curr, 0);
    } else if (this.order) {
      this.invoice = new Invoice();
      this.invoice.salesRealizatorDistribution = [];
      this.invoice.distribution = [];
      setTimeout(() => {
        this.formGroup.patchValue({
          order: { taskNumber: this.order.taskNumber, orderId: this.order.id },
          leadCreator: this.consultants.find(_ => _.id == this.order.leadCreatorId),
        });
      });
    } else {
      this.invoice = new Invoice();
      this.invoice.distribution = [];
      this.invoice.salesRealizatorDistribution = [];
    }

    this.filteredOrders = this.orderControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const taskNumber = typeof value === 'string' ? value : value?.taskNumber;
        return taskNumber ? this.filterOrders(taskNumber as string) : this.orderIds.slice();
      }),
    );

    this.orderControl.valueChanges.subscribe(async _ => {
      if (_ && typeof _ !== "string") {
        this.order = await this.orderService.get(_.orderId);
        const consultant = this.consultants.find(_ => _.id == this.order.consultantId);
        const leadCreator = this.consultants.find(_ => _.id == this.order.leadCreatorId);

        this.invoice.orderId = this.order.id;
        this.invoice.status = InvoiceStatus.Manual;
        this.invoice.projectLeadId = leadCreator?.id;
        this.invoice.departmentId = consultant?.departmentId;
        this.invoice.taskNumber = this.order.taskNumber;
        this.invoice.customerName = this.order.customerAndTask;
        this.invoice.economicCustomerNumber = this.order.economicCustomerNumber;

        this.leadCreatorControl.setValue(leadCreator);
      }
    });
    if (this.invoice?.order) {
      setTimeout(() => {
        console.log(this.invoice?.salesRealizatorDistribution);
        this.formGroup.patchValue({
          order: { taskNumber: this.invoice?.taskNumber, orderId: this.invoice?.orderId },
          salesRealizator: this.invoice?.salesRealizatorDistribution.map(_ => _.consultantId),
          executor: this.invoice?.distribution.map(_ => _.consultantId),
        });
      });
    }

    this.filteredLeadCreators = this.leadCreatorControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const initials = typeof value === 'string' ? value : value?.initials;
        return initials ? this.filterLeadCreators(initials as string) : this.consultants.slice();
      }),
    );

    this.leadCreatorControl.valueChanges.subscribe(_ => {
      if (_ && typeof _ !== "string") {
        this.invoice.projectLeadId = _.id;
        if (!this.invoice.leadCreatorDistribution) {
          this.invoice.leadCreatorDistribution = new DistributionAmount(this.order.leadCreatorId, this.order.id, this.invoiceAmount, null, null, this.invoice.id);
        }
      }
    });

    this.salesRealizatorControl.valueChanges.subscribe(salesRealizators => {
      if (this.invoice?.order && salesRealizators?.length == 0) return;
      let toRemove = this.invoice.salesRealizatorDistribution
        ?.map(_ => _.consultantId)
        ?.filter(_ => !salesRealizators?.includes(_));

      toRemove?.forEach(e => {
        let idx = this.invoice.salesRealizatorDistribution.findIndex(__ => __.consultantId == e);
        this.invoice.salesRealizatorDistribution.splice(idx, 1);
      });

      salesRealizators?.forEach(e => {
        if (!this.invoice.salesRealizatorDistribution?.some(__ => __.consultantId == e)) {
          let s = this.order.salesRealizators.find(_ => _.consultantId == e);
          this.invoice.salesRealizatorDistribution.push(new DistributionAmount(e, this.order.id, (s?.amount / this.order.projectSumExVat) * this.invoiceAmount, null, this.invoice.id));
        }
      });
    });

    this.executorControl.valueChanges.subscribe(executors => {
      if (this.invoice?.order && executors?.length == 0) return;
      let toRemove = this.invoice.distribution
        ?.map(_ => _.consultantId)
        ?.filter(_ => !executors?.includes(_));

      toRemove?.forEach(e => {
        let idx = this.invoice.distribution.findIndex(__ => __.consultantId == e);
        this.invoice.distribution.splice(idx, 1);
      });

      executors?.forEach(e => {
        if (!this.invoice.distribution?.some(__ => __.consultantId == e)) {
          let s = this.order.executors.find(_ => _.consultantId == e);
          this.invoice.distribution.push(new DistributionAmount(e, this.order.id, (s?.amount / this.order.projectSumExVat) * this.invoiceAmount, this.invoice.id));
        }
      });
    });

    this.loading = false;
  }

  private filterOrders(value: string): OrderId[] {
    const filterValue = value.toLowerCase();
    return this.orderIds.filter(option => option.taskNumber.toLowerCase().includes(filterValue));
  }

  orderDisplayFn(order: OrderId): string {
    return order && order.taskNumber ? order.taskNumber : '';
  }

  private filterLeadCreators(value: string): Consultant[] {
    const filterValue = value.toLowerCase();
    return this.consultants.filter(option => option.initials.toLowerCase().includes(filterValue));
  }

  dateSelected(event: MatDatepickerInputEvent<Moment>, datepicker: MatDatepicker<Date>) {
    if (datepicker) {
      datepicker.close();
    }

    this.invoice.date = event.value.add(12, "hours").toDate();
  }

  consultant(id: string) {
    return this.consultants?.find(_ => _.id == id);
  }

  async save() {
    if (!this.valid()) {
      this.formGroup.markAllAsTouched();
      this.ngModels.forEach(_ => _.control.markAllAsTouched());
    } else {
      try {
        if (!this.invoice.tasks || this.invoice.tasks?.length == 0) {
          this.invoice.tasks = [new InvoiceTask("Manuelt oprettet", 1, this.invoiceAmount - this.totalExpenses())];
        }
        else {
          this.invoice.tasks[0].price = this.invoiceAmount - this.totalExpenses();
        }
        await this.invoiceService.save(this.invoice, this.order.invoicingFinished);
        this.snackBar.open("Fakturaen er gemt");
        this.reset();
        this.close.emit(false);
      } catch (error: any) {
        console.error(error);
        if (error.status == 500) {
          this.snackBar.open("Faktura nr. eksisterer allerede");
        } else {
          this.snackBar.open("Der skete en fejl. Prøv igen senere");
        }
      }
    }
  }

  reset() {
    this.ngModels.forEach(_ => _.control.reset());
    this.formGroup.reset();
    this.invoice = new Invoice();
    this.order = null;
    this.invoice.salesRealizatorDistribution = [];
    this.invoice.distribution = [];
  }

  valid() {
    return !this.salesRealizatorAmountError() && !this.executorAmountError()
      && this.formGroup.valid && this.ngModels.map(_ => _.valid).every(_ => _);
  }

  salesRealizatorAmountError() {
    let total = this.invoice.salesRealizatorDistribution?.map(_ => +_.amount).reduce((prev, curr) => prev + curr, 0);
    let totalInvoice = this.invoiceAmount - this.totalExpenses();
    return !(total >= totalInvoice - 0.01 && total <= totalInvoice + 0.01);
  }

  executorAmountError() {
    let total = this.invoice.distribution?.map(_ => +_.amount).reduce((prev, curr) => prev + curr, 0);
    let totalInvoice = this.invoiceAmount - this.totalExpenses();
    return !(total >= totalInvoice - 0.01 && total <= totalInvoice + 0.01);
  }

  totalExpenses() {
    return 0;//this.invoice.distribution?.map(_ => +_.expenses || 0).reduce((prev, curr) => prev + curr, 0);
  }

  updateAmount(da: DistributionAmount, percentage: number) {
    da.amount = (percentage / 100) * (this.invoiceAmount - this.totalExpenses());
  }

  invoiceAmountSet($event: any) {
    this.invoiceAmount = $event;
    this.salesRealizatorControl.setValue(this.order.salesRealizators.map(_ => _.consultantId));
    this.executorControl.setValue(this.order.executors.map(_ => _.consultantId));
    this.invoice.leadCreatorDistribution.amount = this.invoiceAmount;
  }
}
