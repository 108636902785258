<div style="display: flex;flex-direction: row;">
  <h2 mat-dialog-title style="display: inline;flex: auto;">Konsulent</h2>
  <button mat-button mat-dialog-close>X</button>
</div>
<mat-dialog-content>
  @if (!loading) {
  <div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Navn</mat-label>
        <input matInput type="text" [(ngModel)]="consultant.name" required>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Initialer</mat-label>
        <input matInput type="text" [(ngModel)]="consultant.initials" (ngModelChange)="checkExisting()"
          [readonly]="consultant.id" required>
      </mat-form-field>
      @if (consultantExists) {
      <mat-error>Disse initialer er allerede benyttet</mat-error>
      }

    </div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="text" [(ngModel)]="consultant.email" required email>
      </mat-form-field>
    </div>
    <app-autocomplete-chips [formControl]="departmentControl" label="Forretningsområde"
      [displayFn]="departmentsService.displayFn" [valueFn]="departmentsService.valueFn" [options]="departments"
      [multiple]="false" [required]="true"></app-autocomplete-chips>
  </div>

  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" mat-dialog-close>Annuller</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!valid()">Gem</button>
</mat-dialog-actions>