import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Consultant } from 'src/app/models/consultant.model';
import { Department } from 'src/app/models/department.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss']
})
export class ConsultantComponent implements OnInit {
  loading = true;
  consultant: Consultant;

  departments: Department[];
  departmentsDropdown = [];
  departmentControl: FormControl;
  consultantExists: boolean;
  
  @ViewChildren(NgModel) ngModels: QueryList<NgModel>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConsultantComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected consultants: ConsultantService,
    protected departmentsService: DepartmentService,
  ) {
    this.consultant = data.consultant;

    this.departmentControl = this.fb.control(this.consultant.departmentId);

  }

  async ngOnInit(): Promise<void> {
    this.departments = await this.departmentsService.list();

    this.departmentsDropdown = this.departments.map(_ => {
      return { display: _.name, value: _.id };
    });

    this.departmentControl.valueChanges.subscribe(_ => {
      this.consultant.departmentId = _;
    });

    this.loading = false;
  }

  async checkExisting() {
    try {
      this.consultantExists = await this.consultants.exists(this.consultant.initials.toUpperCase());
    } catch (error) {
    }
  }

  async save() {
    if (!this.ngModels.map(_ => _.valid).every(_ => _) || !this.valid()) {
      this.departmentControl.markAllAsTouched();
      this.ngModels.forEach(_ => _.control.markAllAsTouched());
    } else {
      try {
        this.consultant.initials = this.consultant.initials.toUpperCase();
        await this.consultants.save(this.consultant);
        this.dialogRef.close(this.consultant);
      } catch (error) {
        console.error(error);
      }
    }


  }

  valid() {
    if (!this.consultant.name || !this.consultant.initials || !this.consultant.email || !this.consultant.departmentId)
      return false;

    if (!this.consultant.id && this.consultantExists)
      return false;

    return true;
  }
}
