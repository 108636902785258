@if (!loading) {
<div style="display: flex;gap: 1rem;">
    <app-autocomplete-chips [formControl]="consultantControl" label="Konsulent"
    [displayFn]="consultantsService.displayFn" [valueFn]="consultantsService.valueFn" [options]="consultants" [multiple]="false"></app-autocomplete-chips>

    <mat-form-field appearance="outline" dayMonthYearFormat style="height:75px">
        <mat-label>Periode (MM/DD/YYYY – MM/DD/YYYY)</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Fra" [formControl]="fromControl">
            <input matEndDate placeholder="Til" [formControl]="toControl">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="exportExcel()" style="margin-top: 10px;">Eksportér til Excel</button>
    <button mat-raised-button color="primary" (click)="exportPdf()" style="margin-top: 10px;">Eksportér til PDF</button>
</div>
}

    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dato </th>
            <td mat-cell *matCellDef="let element"> {{element?.date | date:'dd/MM/yyyy'}} </td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
        </ng-container>
        <ng-container matColumnDef="invoiceId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fakt.nr </th>
            <td mat-cell *matCellDef="let element"> {{element?.invoiceId}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="taskNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Opgave ID </th>
            <td mat-cell *matCellDef="let element"> {{element?.taskNumber}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Kunde </th>
            <td mat-cell *matCellDef="let element"> {{element?.customer}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="leadCreator">
            <th mat-header-cell *matHeaderCellDef> Lead Creator </th>
            <td mat-cell *matCellDef="let element"> {{element?.leadCreator | number:'1.0-0':'da'}} </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals?.leadCreator | number: '1.0-0':'da'}}</td>
        </ng-container>
        <ng-container matColumnDef="salesRealizator">
            <th mat-header-cell *matHeaderCellDef> Salgsrealisator </th>
            <td mat-cell *matCellDef="let element"> {{element?.salesRealizator | number:'1.0-0':'da'}} </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals?.salesRealizator | number: '1.0-0':'da'}}</td>
        </ng-container>
        <ng-container matColumnDef="executor">
            <th mat-header-cell *matHeaderCellDef> Udførende </th>
            <td mat-cell *matCellDef="let element"> {{element?.executor | number:'1.0-0':'da'}} </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals?.executor | number: '1.0-0':'da'}}</td>
        </ng-container>
        <ng-container matColumnDef="sum">
            <th mat-header-cell *matHeaderCellDef> Sum </th>
            <td mat-cell *matCellDef="let element"> {{element?.sum | number:'1.0-0':'da'}}</td>
            <td mat-footer-cell *matFooterCellDef class="right-align">{{totals?.sum | number: '1.0-0':'da'}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row class="footer-row" *matFooterRowDef="displayedColumns;sticky:true"></tr>
    </table>