import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  DoCheck,
  forwardRef,
  Injector,
  AfterViewInit,
  HostListener,
  ChangeDetectionStrategy,
  Inject,
  OnInit
} from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, FormControlDirective, FormControlName, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "mat-select-autocomplete",
  templateUrl: "select-autocomplete.component.html",
  styleUrls: ["select-autocomplete.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAutocompleteComponent),
      multi: true,
    }
  ]
})
export class SelectAutocompleteComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  @Input() selectPlaceholder: string = "Søg...";
  @Input() label: string;
  @Input() placeholder: string;
  @Input() options;
  @Input() disabled = false;
  @Input() display = "display";
  @Input() value = "value";
  formControl: FormControl;
  @Input() errorMsg: string = "Field is required";
  @Input() showErrorMsg = false;
  @Input() selectedOptions;
  @Input() multiple = true;
  @Input() required = false;
  @Input() searchbar = true;

  // New Options
  @Input() labelCount: number = 1;
  @Input() appearance: MatFormFieldAppearance = "outline";

  @Output()
  selectionChange: EventEmitter<any> = new EventEmitter();

  @ViewChild("selectElem") selectElem;

  // @HostListener('keydown.enter', ['$event'])
  // handleKeyDown(event: KeyboardEvent) {
  //   this.selectElem.toggle();
  // }


  filteredOptions: Array<any> = [];
  selectedValue: Array<any> = [];

  displayString = "";

  onChange = (val) => { };

  onTouched = () => { };

  constructor(
    protected injector: Injector
  ) { }

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl, null);
    
    if (ngControl) {
      this.formControl = ngControl.control as FormControl;
    }
  }

  ngAfterViewInit(): void {
  }

  writeValue(obj: any): void {
    this.filteredOptions = this.options;
    if (this.selectedOptions) {
      this.selectedValue = this.selectedOptions;
    } else if (this.formControl?.value) {
      this.selectedValue = this.formControl.value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.disabled = isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl?.enable();
    }
  }

  ngOnChanges() {

    this.filteredOptions = this.options;
    if (this.selectedOptions) {
      this.selectedValue = this.selectedOptions;
    } else if (this.formControl?.value) {
      this.selectedValue = this.formControl.value;
    }
  }

  ngDoCheck() {
    if (!this.selectedValue?.length) {
      this.selectionChange.emit(this.selectedValue);
    }
  }

  toggleDropdown() {
    this.selectElem.toggle();
  }

  filterItem(value) {
    this.filteredOptions = this.options.filter(
      item => item[this.display].toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    // this.selectAllChecked = true;
    this.filteredOptions.forEach(item => {
      if (!this.selectedValue.includes(item[this.value])) {
        // this.selectAllChecked = false;
      }
    });
    if (!this.filteredOptions.length) {
      // this.selectAllChecked = false;
    }
  }

  hideOption(option) {
    return !(this.filteredOptions?.indexOf(option) > -1);
  }

  // Returns plain strings array of filtered values
  getFilteredOptionsValues() {
    const filteredValues = [];
    this.filteredOptions.forEach(option => {
      filteredValues.push(option.value);
    });
    return filteredValues;
  }

  onDisplayString() {
    this.displayString = "";
    if (this.selectedValue && this.selectedValue.length) {
      let displayOption = [];
      if (this.multiple) {
        // Multi select display
        for (let i = 0; i < this.labelCount; i++) {
          displayOption[i] = this.options.filter(
            option => option[this.value] === this.selectedValue[i]
          )[0];
        }
        if (displayOption.length) {
          for (let i = 0; i < displayOption.length; i++) {
            if (displayOption[i] && displayOption[i][this.display]) {
              this.displayString += displayOption[i][this.display] + ",";
            }
          }
          this.displayString = this.displayString.slice(0, -1);
          if (
            this.selectedValue.length > 1 &&
            this.selectedValue.length > this.labelCount
          ) {
            this.displayString += ` (+${this.selectedValue.length -
              this.labelCount} others)`;
          }
        }
      } else {
        // Single select display
        displayOption = this.options.filter(
          option => option[this.value] === this.selectedValue
        );
        if (displayOption.length) {
          this.displayString = displayOption[0][this.display];
        }
      }
    }
    return this.displayString;
  }

  onSelectionChange(val) {
    const filteredValues = this.getFilteredOptionsValues();
    let count = 0;
    if (this.multiple) {
      this.selectedValue.filter(item => {
        if (filteredValues.includes(item)) {
          count++;
        }
      });
      // this.selectAllChecked = count === this.filteredOptions.length;
    }
    this.selectedValue = val.value;
    this.selectionChange.emit(this.selectedValue);
  }

  public trackByFn(index, item) {
    return item.value;
  }
}
