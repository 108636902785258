import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { Department } from '../models/department.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends BaseHttpService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public displayFn(department: Department): string {
    return department && department.name ? department.name : '';
  }

  public valueFn(department: Department): string {
    return department && department.id ? department.id : '';
  }

  public list() {
    return firstValueFrom(this.http.get<Department[]>(`${this.baseApiUrl}/department/list`));
  }

  public get(id:number) {
    return firstValueFrom(this.http.get<Department>(`${this.baseApiUrl}/department/${id}`));
  }

  public save(department: Department) {
    return firstValueFrom(this.http.post<Department>(`${this.baseApiUrl}/department/save`, department));
  }
}