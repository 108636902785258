import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConsultantService } from 'src/app/services/consultant.service';
import { ConsultantComponent } from './consultant/consultant.component';
import { Consultant } from 'src/app/models/consultant.model';
import { Department } from 'src/app/models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss']
})
export class ConsultantsComponent implements OnInit {

  loading = true;
  displayedColumns: string[] = ['actions', 'name', 'initials', 'department', 'email'];
  @ViewChild(MatTable<Consultant>) table: MatTable<Consultant>;
  dataSource: MatTableDataSource<Consultant>;
  departments: Department[];

  constructor(
    private snackBar: MatSnackBar,
    protected dialog: MatDialog,
    protected consultants: ConsultantService,
    protected departmentsService: DepartmentService
  ) {

  }

  async ngOnInit(): Promise<void> {
    await this.loadConsultants();
  }

  async loadConsultants() {
    this.loading = true;
    this.departments = await this.departmentsService.list();
    let consultants = await this.consultants.list();
    // consultants.sort((a, b) => {
    //   if (a.name?.toLocaleLowerCase()?.charCodeAt(0) > b.name?.toLocaleLowerCase()?.charCodeAt(0)) return 1;
    //   else if (a.name?.toLocaleLowerCase()?.charCodeAt(0) < b.name?.toLocaleLowerCase()?.charCodeAt(0)) return -1;
    //   return 0;
    // });
    this.dataSource = new MatTableDataSource(consultants);
    this.loading = false;
  }

  department(id: string) {
    return this.departments.find(_ => _.id == id)?.name;
  }

  createConsultant() {
    let dialogRef = this.dialog.open(ConsultantComponent, {
      width: '50%',
      data: { consultant: new Consultant() },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async created => {
      if (created) {
        await this.loadConsultants();
      }
    });
  }

  openConsultant(consultant: Consultant) {
    let dialogRef = this.dialog.open(ConsultantComponent, {
      width: '50%',
      data: { consultant: { ...consultant } },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(updated => {
      if (updated) {
        let idx = this.dataSource.data.findIndex(_ => _.id == updated.id);
        this.dataSource.data[idx] = updated;
        this.table.renderRows();
      }
    });
  }

  async deleteConsultant(consultant: Consultant) {
    if (await this.consultants.canDelete(consultant.id)) {
      if (window.confirm("Vil du slette " + consultant.initials)) {
        if (await this.consultants.delete(consultant.id)) {
          await this.loadConsultants();
        }
      }
    } else {
      this.snackBar.open(consultant.initials + " kan ikke slettes da denne indgår i en ordre eller faktura");
    }
  }
}
