import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Order } from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseHttpService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public taskNumber(consultantId: string, year: Date) {
    return firstValueFrom(this.http.get(`${this.baseApiUrl}/order/task-number`, { responseType: "text", params: { consultantId: consultantId, year: year?.toISOString() } }));
  }

  public taskNumberExists(taskNumber: string) {
    return firstValueFrom(this.http.get<boolean>(`${this.baseApiUrl}/order/task-number-exists`, { params: { taskNumber: taskNumber } }));
  }

  public feeBasis(consultant: string, from: Date, to: Date) {
    return firstValueFrom(this.http.get<Order[]>(`${this.baseApiUrl}/order/fee-basis`,
      { params: { consultant: consultant, from: from.toISOString(), to: to.toISOString() } }));
  }

  public taskNumbers() {
    return firstValueFrom(this.http.get<{taskNumber: string, orderId: string}[]>(`${this.baseApiUrl}/order/task-numbers`));
  }

  public list() {
    return firstValueFrom(this.http.get<Order[]>(`${this.baseApiUrl}/order/list`));
  }

  public get(id: string) {
    return firstValueFrom(this.http.get<Order>(`${this.baseApiUrl}/order/${id}`));
  }

  public delete(id: string) {
    return firstValueFrom(this.http.delete<boolean>(`${this.baseApiUrl}/order/${id}`));
  }

  public save(o: Order) {
    let order = { ...o };
    order.consultant = order.leadCreator = order.department = null;
    return firstValueFrom(this.http.post<Order>(`${this.baseApiUrl}/order/save`, order));
  }

  public exportAllToExcel() {
    return firstValueFrom(this.http.get(`${this.baseApiUrl}/order/export-all-to-excel`,
      { observe: "response", responseType: "blob" }));
  }
}
