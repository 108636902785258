import { Component } from '@angular/core';
import { FeeBasisAdjustmentComponent } from './fee-basis-adjustment/fee-basis-adjustment.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {

}
