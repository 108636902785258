import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { AdminComponent } from './admin/admin.component';
import { PivotViewComponent } from './pivot-view/pivot-view.component';
import { ConsultantSummaryComponent } from './consultant-summary/consultant-summary.component';
import { BrowserUtils } from '@azure/msal-browser';
import { LoginComponent } from './login/login.component';
import { MsalGuard } from '@azure/msal-angular';
import { GroupGuard } from './group.guard';
import { groups } from './auth-config';
import { InvoicesComponent } from './invoices/invoices.component';
import { FeeBasisComponent } from './fee-basis/fee-basis.component';

const routes: Routes = [
  { path: "", redirectTo: "orders", pathMatch: "full" },
  // { path: "login", component: LoginComponent, title: "Login" },
  {
    path: "orders",
    component: OrdersComponent,
    title: "Ordrebog",
    canActivate: [GroupGuard],
    data: {
      requiredGroups: [groups.consultant, groups.admin]
    }
  },
  {
    path: "consultant-summary",
    component: ConsultantSummaryComponent,
    title: "Totaler",
    canActivate: [GroupGuard],
    data: {
      requiredGroups: [groups.consultant, groups.admin]
    }
  },
  {
    path: "pivot",
    component: PivotViewComponent,
    title: "Rapport",
    canActivate: [GroupGuard],
    data: {
      requiredGroups: [groups.consultant, groups.admin]
    }
  },
  {
    path: "invoices",
    component: InvoicesComponent,
    title: "Fakturaer",
    canActivate: [GroupGuard],
    data: {
      requiredGroups: [groups.consultant, groups.admin]
    }
  },
  {
    path: "fee-basis",
    component: FeeBasisComponent,
    title: "Honorargrundlag",
    canActivate: [GroupGuard],
    data: {
      requiredGroups: [groups.consultant, groups.admin]
    }
  },
  {
    path: "admin",
    component: AdminComponent,
    title: "Administration",
    canActivate: [GroupGuard],
    data: {
      requiredGroups: [groups.admin]
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // useHash: true,
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? "enabledNonBlocking"
        : "disabled", // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
