import { ApplicationRef, Component, inject, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject, concat, filter, first, firstValueFrom, interval, map, shareReplay, takeUntil } from 'rxjs';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  private breakpointObserver = inject(BreakpointObserver);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private snackBar: MatSnackBar,
    protected auth: AuthService
  ) {
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
    const everyHour$ = interval(1 * 60 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    if (updates.isEnabled)
      everyHourOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await updates.checkForUpdate();
          if (updateFound) {
            snackBar.open(`En nyere version er tilgængelig`, "Opdatér siden", {
              duration: 0,
              verticalPosition: 'top'
            }).onAction().subscribe(() => {
              document.location.reload();
            });
          }
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });

    updates.unrecoverable.subscribe(_ => {
      snackBar.open(`Der er sket en fejl: ${_.reason}.`, "Opdatér siden", {
        duration: 0,
        verticalPosition: 'top'
      }).onAction().subscribe(() => {
        document.location.reload();
      });;
    });
  }

  async ngOnInit(): Promise<void> {
    let result = await firstValueFrom(this.authService.handleRedirectObservable());

    if (result?.account) {
      this.authService.instance.setActiveAccount(result.account);
      this.auth.activeAccount = result.account;
    } else if (this.authService.instance.getAllAccounts()?.length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.auth.activeAccount = this.authService.instance.getActiveAccount();
    }

    this.isIframe = window !== window.parent && !window.opener;
  }

  async logout() {
    this.authService.logoutRedirect();
  }

  isStaging() {
    return environment.isStaging;
  }
}
