import { Consultant } from "./consultant.model";

export class ConsultantSummary {

constructor(
    public consultant: Consultant,
    public personalRevenue: number,
    public invoicedPersonalRevenue: number,
    public weightedPipeline: number,
    public leads: number,
    public realizedSales: number
) {
    
}
}