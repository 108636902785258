import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { loginRequest } from '../auth-config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {


  }
  async ngOnInit(): Promise<void> {
    // try {
    //   console.log(this.authService.instance.getAllAccounts());
    //   let result = await this.authService.instance.ssoSilent(loginRequest);
    //   console.log("SILENT", result);
    //   if (result.account) {
    //     this.router.navigateByUrl("orders");
    //   }
    // } catch (error) {
    // }
  }

  login() {

    // this.authService.loginPopup()
    //   .subscribe({
    //     next: (result) => {
    //       this.authService.instance.setActiveAccount(result.account);
    //       this.router.navigateByUrl("orders");
    //     },
    //     error: (error) => console.log(error)
    //   });

  }
}
