<button mat-raised-button color="primary" style="width: fit-content;" (click)="createConsultant()">
  <mat-icon>add</mat-icon> Opret ny konsulent
</button>

@if (!loading) {
<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="openConsultant(element)" matTooltip="Rediger">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteConsultant(element)" matTooltip="Slet">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Navn </th>
      <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="initials">
      <th mat-header-cell *matHeaderCellDef> Initialer </th>
      <td mat-cell *matCellDef="let element"> {{element?.initials}} </td>
    </ng-container>
    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef> Forretningsområde </th>
      <td mat-cell *matCellDef="let element"> {{department(element.departmentId)}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
}