import { Injectable } from "@angular/core";
import { AccountWithGroupClaims } from "../models/claims-account.model";
import { groups } from "../auth-config";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public activeAccount: AccountWithGroupClaims;

    constructor() {
    }

    isConsultant() {
        return this.activeAccount?.idTokenClaims?.groups?.includes(groups.consultant);
    }

    isAdmin() {
        return this.activeAccount?.idTokenClaims?.groups?.includes(groups.admin);
    }
}