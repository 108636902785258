
<div style="display: flex;flex-direction: row;">
  <h2 mat-dialog-title style="display: inline;flex: auto;">Faktura</h2>
  <button mat-button (click)="close(false)">X</button>
</div>
<mat-dialog-content>
  <app-manual-invoicing [invoice]="invoice" [order]="order" (close)="close($event)"></app-manual-invoicing>

</mat-dialog-content>
<mat-dialog-actions>

</mat-dialog-actions>