@if (!loading) {
<div style="display: flex;gap: 1rem;">
    <app-autocomplete-chips [formControl]="orderControl" label="Ordre" [displayFn]="orderDisplayFn"
        [valueFn]="orderValueFn" [options]="orderIds" [multiple]="false" [required]="true"></app-autocomplete-chips>

    <mat-form-field appearance="outline" style="width: 350px;">
        <mat-label>Dato (DD/MM/ÅÅÅÅ)</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="dateControl" (dateChange)="dateSelected($event, dp)"
            required>
        <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp>
        </mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" style="flex: 1">
        <mat-label>Faktura nr.</mat-label>
        <input matInput type="text" [(ngModel)]="feeBasisAdjustment.invoiceNumber" required enterNavigation>
    </mat-form-field>
</div>
<div style="display: flex;gap: 1rem;">
    <mat-form-field appearance="outline" style="flex: 1">
        <mat-label>Kunde</mat-label>
        <input matInput type="text" [(ngModel)]="feeBasisAdjustment.customer" required enterNavigation>
    </mat-form-field>

    <app-autocomplete-chips [formControl]="consultantControl" label="Konsulent"
        [displayFn]="consultantService.displayFn" [valueFn]="consultantService.valueFn" [options]="consultants"
        [multiple]="false" [required]="true"></app-autocomplete-chips>
</div>
<h3>Regulering</h3>
<mat-form-field appearance="outline" style="width: 33%;">
    <mat-label>Lead creator</mat-label>
    <input matInput type="text" [ngModel]="feeBasisAdjustment.leadCreator | number:'1.0-2':'da'"
        (ngModelChange)="feeBasisAdjustment.leadCreator = $event" enterNavigation currencyFormat="1.0-2">
</mat-form-field>
<mat-form-field appearance="outline" style="width: 33%;">
    <mat-label>Salgsrealisator</mat-label>
    <input matInput type="text" [ngModel]="feeBasisAdjustment.salesRealizator | number:'1.0-2':'da'"
        (ngModelChange)="feeBasisAdjustment.salesRealizator = $event" enterNavigation currencyFormat="1.0-2">
</mat-form-field>
<mat-form-field appearance="outline" style="width: 33%;">
    <mat-label>Udførende</mat-label>
    <input matInput type="text" [ngModel]="feeBasisAdjustment.executor | number:'1.0-2':'da'"
        (ngModelChange)="feeBasisAdjustment.executor = $event" enterNavigation currencyFormat="1.0-2">
</mat-form-field>
<div style="display: flex;gap: 1rem;">
    <button mat-raised-button color="primary" style="width: fit-content;" (click)="save()">
        Bogfør
    </button>

    <button mat-raised-button color="primary" style="width: fit-content;" (click)="reset()">
        Nulstil
    </button>
</div>
}