<mat-form-field style="width: 100%;" [class.has-items]="multiple ? control?.value && control.value?.length > 0 : control?.value" [class.ng-touched]="control?.touched" [class.ng-invalid]="control?.invalid"
    [appearance]="appearance" (click)="input.focus()">
    <mat-label>{{ label }} @if(required) {*}</mat-label>
    <mat-chip-grid #chipGrid>
        @if (multiple) {
        @for (value of control?.value; track value) {
        <mat-chip-row (removed)="remove(value)">
            {{ displayFn(option(value)) }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
        }
        } @else if(control?.value) {
        <mat-chip-row (removed)="remove(control.value)">
            {{ displayFn(option(control.value)) }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
        }
    </mat-chip-grid>
    <input [placeholder]="placeholder" #input [formControl]="inputFormControl" [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)" (focus)="focused = true" (blur)="control.markAsTouched();focused = false" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
        @for (option of filteredOptions | async; track option) {
        <mat-option>{{ displayFn(option) }}</mat-option>
        }
    </mat-autocomplete>
</mat-form-field>