import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropDownValue } from '../pivot-view.component';

@Component({
  selector: 'app-pivot-view-drowdown',
  templateUrl: './pivot-view-drowdown.component.html',
  styleUrls: ['./pivot-view-drowdown.component.scss']
})
export class PivotViewDrowdownComponent {
  @Input() title: string;
  @Input() options: DropDownValue[];
  @Input() set value(newValue: string) {
    this.selectedOption = newValue;
  }
  @Input() identifier: string;
  @Output() valueChange = new EventEmitter<{identifier: string, value: string}>();

  selectedOption: string;

  emitValueChange() {
    this.valueChange.emit({identifier: this.identifier, value: this.selectedOption});
  }
}
