import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { Invoice, Order } from '../models/order.model';
import { Settings } from '../models/settings.model';

@Injectable({
  providedIn: 'root'
})
export class PivotService extends BaseHttpService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public filters() {
    return firstValueFrom(this.http.get<any>(`${this.baseApiUrl}/pivot/filters`));
  }

  exportExcel(year?: string,
    projectType?: string,
    department?: string,
    consultant?: string,
    leadCreator?: string,
    salesRealizer?: string,
    executor?: string) {
    return firstValueFrom(this.http.get(`${this.baseApiUrl}/pivot/excel`,
      {
        observe: "response", responseType: "blob",
        params: { year, projectType, department, consultant, leadCreator, salesRealizer, executor }
      }));
  }

  public get(
    year?: string,
    projectType?: string,
    department?: string,
    consultant?: string,
    leadCreator?: string,
    salesRealizer?: string,
    executor?: string,
    invoicingFinishedValue?: boolean
  ) {
    let params = new HttpParams();

    if (year) {
      params = params.set('year', year);
    } else {
      params = params.set('year', "");
    }

    if (projectType !== undefined) {
      params = params.set('projectType', projectType);
    } else {
      params = params.set('projectType', "");
    }

    if (department !== undefined) {
      params = params.set('department', department);
    } else {
      params = params.set('department', "");
    }

    if (consultant !== undefined) {
      params = params.set('consultant', consultant);
    } else {
      params = params.set('consultant', "");
    }

    if (leadCreator !== undefined) {
      params = params.set('leadCreator', leadCreator);
    } else {
      params = params.set('leadCreator', "");
    }

    if (salesRealizer !== undefined) {
      params = params.set('salesRealizer', salesRealizer);
    } else {
      params = params.set('salesRealizer', "");
    }

    if (executor !== undefined) {
      params = params.set('executor', executor);
    } else {
      params = params.set('executor', "");
    }

    if (invoicingFinishedValue !== undefined) {
      params = params.set('invoicingFinished', !!invoicingFinishedValue);
    } else {
      params = params.set('invoicingFinished', "");
    }

    return firstValueFrom(this.http.get<Order[]>(`${this.baseApiUrl}/pivot`, { params: params }));
  }
}