import { ChangeDetectorRef, Component } from '@angular/core';
import { Order } from '../models/order.model';
import { OrderService } from '../services/order.service';
import { ConsultantService } from '../services/consultant.service';
import { DepartmentService } from '../services/department.service';
import { Consultant } from '../models/consultant.model';
import { PivotService } from '../services/pivot.service';

export declare type DropDownValue = { display: any, value: any };

@Component({
  selector: 'app-pivot-view',
  templateUrl: './pivot-view.component.html',
  styleUrls: ['./pivot-view.component.scss']
})
export class PivotViewComponent {
  loading = true;
  yearOptions: DropDownValue[];
  projectTypeOptions: DropDownValue[] = [{ display: "Ordre", value: "Projekt" }, { display: "Pipeline", value: "Pipeline" }];
  departmentOptions: DropDownValue[];
  consultantOptions: DropDownValue[];
  leadCreatorOptions: DropDownValue[];
  salesRealizerOptions: DropDownValue[];
  executorOptions: DropDownValue[];
  displayedTableColumns: string[];

  yearValue;
  projectTypeValue;
  departmentValue;
  consultantValue;
  leadCreatorValue;
  salesRealizerValue;
  executorValue;

  dataSource: Order[];
  filteredDataSource: Order[];

  // consultants: Consultant[];

  filters: any;

  constructor(
    public orders: OrderService,
    protected consultantsService: ConsultantService,
    protected departmentsService: DepartmentService,
    protected pivot: PivotService,
    protected cd: ChangeDetectorRef
    ) { }

  async ngOnInit(): Promise<void> {
    // this.consultants = await this.consultantsService.list();
    this.departmentOptions = (await this.departmentsService.list()).map(_ => ({ display: _.name, value: _.id }));
    this.filters = await this.pivot.filters();
    this.dataSource = await this.pivot.get(this.yearValue, this.projectTypeValue, this.leadCreatorValue, this.salesRealizerValue, this.executorValue);
    // this.setDropdownOptions(this.dataSource);
    this.setFilters();

    this.displayedTableColumns = this.setDisplayedColumns(this.projectTypeValue);
    this.loading = false;
  }

  setFilters() {
    this.yearOptions = this.filters.year.map(_ => ({ display: _, value: _ }));
    this.consultantOptions = this.filters.consultants.map(_ => ({ display: _.initials, value: _.id }));
    this.leadCreatorOptions = this.filters.consultants.map(_ => ({ display: _.initials, value: _.id }));
    this.salesRealizerOptions = this.filters.consultants.map(_ => ({ display: _.initials, value: _.id }));
    this.executorOptions = this.filters.consultants.map(_ => ({ display: _.initials, value: _.id }));
  }

  async exportExcel() {
    let file = await this.pivot.exportExcel(this.yearValue ?? "", this.projectTypeValue ?? "", this.departmentValue ?? "", this.consultantValue ?? "",
      this.leadCreatorValue ?? "", this.salesRealizerValue ?? "", this.executorValue ?? "");
    const blob = new Blob([file.body], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;` });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `rapport.xlsx`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async handleValueChange(event: { identifier: string, value: string }) {
    switch (event.identifier) {
      case "year": {
        this.yearValue = event.value;
        break
      }
      case "projectType": {
        this.projectTypeValue = event.value;
        break
      }
      case "department": {
        this.departmentValue = event.value;
        break
      }
      case "consultant": {
        this.consultantValue = event.value;
        break
      }
      case "leadCreator": {
        this.leadCreatorValue = event.value;
        break
      }
      case "salesRealizer": {
        this.salesRealizerValue = event.value;
        break
      }
      case "executor": {
        this.executorValue = event.value;
        break
      }
      
    }
      this.dataSource = await this.pivot.get(this.yearValue, this.projectTypeValue, this.departmentValue, this.consultantValue, this.leadCreatorValue, this.salesRealizerValue, this.executorValue);
      // this.setDropdownOptions(this.dataSource);
      this.displayedTableColumns = this.setDisplayedColumns(this.projectTypeValue);

  }

  // setDropdownOptions(dataSource: Order[]) {
  // this.yearOptions = this.extractYearValues(dataSource);
  // this.leadCreatorOptions = this.extractLeadCreatorValues(dataSource);
  // this.salesRealizerOptions = this.extractSalesRealizerValues(dataSource);
  // this.executorOptions = this.extractExecutorValues(dataSource);
  // }

  // consultant(id: string) {
  //   return this.consultants.find(_ => _.id == id)?.initials;
  // }

  // extractYearValues(dataSource: Order[]) {
  //   let result: Date[] = [];

  //   dataSource.forEach((order) => {
  //     if (order.year) {
  //       let yearToAdd = new Date(order.year);
  //       if (!result.find(_ => _.getFullYear() == yearToAdd.getFullYear()))
  //         result.push(yearToAdd)
  //     }
  //   });

  //   result = Array.from(result).sort();

  //   return result.map(_ => ({ display: _.getFullYear(), value: _.toISOString() }));
  // }

  // extractLeadCreatorValues(dataSource: Order[]) {
  //   let result: DropDownValue[] = [];
  //   let uniqueValues = [];

  //   dataSource.forEach((order) => {
  //     if (order.leadCreatorId && !uniqueValues.find(_ => _.value == order.leadCreatorId)) {
  //       uniqueValues.push({ display: this.consultant(order.leadCreatorId), value: order.leadCreatorId })
  //     }
  //   });

  //   result = Array.from(uniqueValues).sort();

  //   return result;
  // }

  // extractSalesRealizerValues(dataSource: Order[]) {
  //   let result: DropDownValue[] = [];
  //   let uniqueValues = [];

  //   dataSource.forEach((order) => {
  //     if (order.salesRealizators) {
  //       order.salesRealizators.forEach(salesRealizer => {
  //         if (!uniqueValues.find(_ => _.value == salesRealizer.id))
  //           uniqueValues.push({ display: this.consultant(salesRealizer.consultantId), value: salesRealizer.consultantId });
  //       });
  //     }
  //   });

  //   result = Array.from(uniqueValues).sort();

  //   return result;
  // }

  // extractExecutorValues(dataSource: Order[]) {
  //   let result: DropDownValue[] = [];
  //   let uniqueValues = new Set<DropDownValue>();

  //   dataSource.forEach((order) => {
  //     if (order.executors) {
  //       order.salesRealizators.forEach(e => {
  //         uniqueValues.add({ display: this.consultant(e.consultantId), value: e.consultantId })
  //       });
  //     }
  //   });

  //   result = Array.from(uniqueValues).sort();

  //   return result;
  // }

  setDisplayedColumns(projectType: string): string[] {
    switch (projectType) {
      case 'Projekt': {
        return [
          "consultant",
          "leadCreator",
          "salesRealizators",
          "executors",
          "taskNumber",
          "customerAndTask",
          "projectSum",
          "sales",
          "executorAmount",
          "distribution",
          "invoicingFinished"
        ];
      }
      case 'Pipeline': {
        return [
          "consultant",
          "leadCreator",
          "salesRealizators",
          "executors",
          "taskNumber",
          "customerAndTask",
          "pipelineProjectSum",
          "weightedPipelineProjectSum",
          "sales",
          "executorAmount",
        ];
      }
      default: {
        return [
          "consultant",
          "leadCreator",
          "salesRealizators",
          "executors",
          "taskNumber",
          "customerAndTask",
          "projectSum",
          "pipelineProjectSum",
          "weightedPipelineProjectSum",
          "sales",
          "executorAmount",
          "distribution",
          "invoicingFinished"
        ];
      }
    }
  }
}
